import request from '../utils';

const getLiveError = (formdata) => new Promise((resolve) => {
  const url = '/panel/notification/error';

  const tempData = request.Api(url, formdata, 'post');
  tempData.then((_rawData) => {
    

    if (_rawData.status !== 200) {
      resolve({});
      return;
    }
    if (_rawData.data.success === 'false') {
      resolve({});
      return;
    }

    resolve(_rawData.data.data);
  });
});

export default {
  getLiveError,
};
