<template>
  <svg
    id="nevres_bilecik_1"
    width="2977"
    height="1907"
    viewBox="0 0 2977 1907"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M887 1247.61L1152 1246L1210.5 1270.5V1271.5M1260.5 1453.5L1199 1427H1078.5C1071.83 1414 1057.6 1387.5 1054 1385.5C1049.5 1383 942 1292.5 927 1278C914.652 1266.06 896.205 1252.77 887 1247.61L1260.5 1453.5ZM747.5 715.5H738L685.5 751.5L740 926V956.5L738 1006.5L749 1019C765.8 1041 776.333 1078.83 779.5 1095C779 1101.17 777.1 1115.8 773.5 1125C769.9 1134.2 776 1146.17 779.5 1151L825.5 1197L853 1226C861 1232 878.1 1244.3 882.5 1245.5C883.472 1245.77 885.023 1246.5 887 1247.61L747.5 715.5ZM747.5 715.5V720.5L755.5 733L747.5 715.5ZM747.5 715.5L805 667.5L814.5 644L862.5 536L747.5 715.5Z"
      stroke="#2CFFFE"
    />
    <path
      d="M1019.5 975.5H739"
      stroke="#2CFFFE"
    />
    <path
      d="M1267.54 820.41L1208.91 820.718L1208.92 821.718L1267.55 821.41L1267.54 820.41Z"
      fill="#991B1E"
    />
    <path
      d="M1069.05 785L1065.26 785.074L1065.28 786.074L1069.07 786L1069.05 785Z"
      fill="#991B1E"
    />
    <path
      d="M1050.49 785.071L1048 785.12L1048.02 786.12L1050.51 786.071L1050.49 785.071Z"
      fill="#991B1E"
    />
    <path
      d="M1049 837H1046V840H1049V837Z"
      fill="#696666"
    />
    <path
      d="M1355.47 909.5L1354.98 881.5M746.5 980.5L769.171 980.073L746.5 980.5ZM1354.5 853.5L1354.89 876L1354.5 853.5ZM1317.69 969.732L1356.5 969L1356.17 950L1317.69 969.732ZM1311.5 969.848L1284 970.367L1311.5 969.848ZM1277.55 970.488L1250 971.008L1277.55 970.488ZM1243.57 971.129L1216.16 971.646L1243.57 971.129ZM1210 971.762L1182.15 972.287L1210 971.762ZM1176 972.403L1148 972.931L1176 972.403ZM1142 973.044L1114.5 973.562L1142 973.044ZM1108 973.685L1080.5 974.203L1108 973.685ZM1074.22 974.322L1046.6 974.842L1074.22 974.322ZM1040.5 974.957L1012.65 975.482L1040.5 974.957ZM1006.45 975.599L978.666 976.123L1006.45 975.599ZM972.5 976.239L945 976.758L972.5 976.239ZM938.779 976.875L911 977.399L938.779 976.875ZM904.773 977.516L877 978.04L904.773 977.516ZM871 978.153L843.323 978.675L871 978.153ZM837 978.794L809.298 979.316L837 978.794ZM803 979.435L775.5 979.953L803 979.435ZM1356.06 943.5L1355.58 916L1356.06 943.5Z"
      stroke="#29FD2E"
      stroke-linecap="round"
    />
    <path
      d="M1355.52 912.178L1355.29 884.174M745.953 977.811L768.627 977.584L745.953 977.811ZM1355.05 856.171L1355.24 878.674L1355.05 856.171ZM1317.21 972.074L1356.03 971.684L1355.87 952.682L1317.21 972.074ZM1311.02 972.136L1283.52 972.413L1311.02 972.136ZM1277.07 972.477L1249.52 972.754L1277.07 972.477ZM1243.08 972.819L1215.68 973.094L1243.08 972.819ZM1209.51 973.156L1181.66 973.436L1209.51 973.156ZM1175.51 973.497L1147.5 973.779L1175.51 973.497ZM1141.5 973.839L1114 974.115L1141.5 973.839ZM1107.5 974.18L1080 974.456L1107.5 974.18ZM1073.71 974.52L1046.09 974.797L1073.71 974.52ZM1039.99 974.858L1012.14 975.138L1039.99 974.858ZM1005.94 975.2L978.148 975.479L1005.94 975.2ZM971.981 975.541L944.478 975.817L971.981 975.541ZM938.256 975.88L910.473 976.159L938.256 975.88ZM904.246 976.222L876.469 976.5L904.246 976.222ZM870.468 976.561L842.787 976.839L870.468 976.561ZM836.464 976.902L808.758 977.18L836.464 976.902ZM802.46 977.244L774.956 977.52L802.46 977.244ZM1355.81 946.181L1355.58 918.678L1355.81 946.181Z"
      stroke="#29FD2E"
      stroke-linecap="round"
    />
    <path
      d="M794 885H741V889H794V885Z"
      fill="#FDCB6E"
    />
    <path
      d="M794 889H741V893H794V889Z"
      fill="#FDCB6E"
    />
    <path
      d="M1552 1270H1499V1274H1552V1270Z"
      fill="#FDCB6E"
    />
    <path
      d="M1552 1274H1499V1278H1552V1274Z"
      fill="#FDCB6E"
    />
    <path
      d="M1478 1101H1425V1105H1478V1101Z"
      fill="#FAFD6E"
    />
    <path
      d="M1531 1101H1478V1105H1531V1101Z"
      fill="#FAFD6E"
    />
    <path
      d="M1584 1101H1531V1105H1584V1101Z"
      fill="#FAFD6E"
    />
    <path
      d="M1637 1101H1584V1105H1637V1101Z"
      fill="#FAFD6E"
    />
    <path
      d="M1690 1101H1637V1105H1690V1101Z"
      fill="#FAFD6E"
    />
    <path
      d="M1743 1101H1690V1105H1743V1101Z"
      fill="#FAFD6E"
    />
    <path
      d="M1478 1105H1425V1109H1478V1105Z"
      fill="#FAFD6E"
    />
    <path
      d="M1531 1105H1478V1109H1531V1105Z"
      fill="#FAFD6E"
    />
    <path
      d="M1584 1105H1531V1109H1584V1105Z"
      fill="#FAFD6E"
    />
    <path
      d="M1637 1105H1584V1109H1637V1105Z"
      fill="#FAFD6E"
    />
    <path
      d="M1690 1105H1637V1109H1690V1105Z"
      fill="#FAFD6E"
    />
    <path
      d="M1743 1105H1690V1109H1743V1105Z"
      fill="#FAFD6E"
    />
    <path
      d="M2291.5 794.5H1911L1866.5 715.5V652.5L1882.5 632.5V508.5"
      stroke="#29FD2E"
    />
    <path
      d="M636.106 674.717L677.087 665.845"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M634 663L668 655"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M704 654L711.039 647.215"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M692.085 648.165L702.064 639.294"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M711.038 647.215L720.703 632.703"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M702.064 639.294L709.72 627.761"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M720.703 632.703L726.602 609.003"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M709.721 627.76L714.678 607.673"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M726.602 609.004L725.724 572.757"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M714.678 607.673L713.863 573.01"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M725.724 572.757L725.284 540.059"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M713.863 573.01L713.423 538.411"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M725.284 540.059L736.142 501.848"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M713.423 538.411L724.908 498.046"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M736.141 501.848L751.58 460.785"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M724.908 498.045L740.597 456.286"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M751.58 460.785L760.303 440"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M740.597 456.286L742.166 452.547"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M681.542 465.347L661.648 474.219"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M664.912 485.879L686.688 476.12"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M681.542 465.347L716.624 446.844"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M686.688 476.12L730.619 452.991"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M716.624 446.844L728.924 441.077L760.303 440"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M730.619 452.99L742.166 452.547"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M245 616.481L269.475 651.46"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M261.631 619.523L279.015 644.363"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M269.476 651.46L302.674 695.248"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M279.015 644.363L312.088 687.96"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M302.674 695.248L322.82 721.799"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M312.088 687.961L333.112 715.716"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M379.05 728.643L425.993 717.364"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M376.226 717.047L423.483 705.704"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M425.993 717.364L459.443 710.71"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M423.483 705.704L457.874 698.797"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M459.443 710.71L480.278 709.189"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M457.874 698.797L479.086 697.276"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M480.278 709.189L491.449 707.668"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M479.086 697.276L488.751 695.945"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M491.449 707.668L533.873 693.791"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M488.751 695.945L530.736 682.194"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M533.874 693.791L594.372 679.913"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M530.736 682.194L592.301 668.063"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M594.372 679.913L636.106 674.717"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M592.301 668.063L634.16 662.867"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M459.882 541.39L449.653 549.501"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M465.781 551.972L453.92 561.351"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M449.653 549.501L372.084 552.099"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M453.92 561.351L373.465 564.012"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M372.084 552.099L343.906 557.865"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M373.465 564.012L347.232 569.398"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M343.906 557.866L317.234 568.195"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M347.232 569.398L322.631 578.904"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M317.234 568.194L271.923 597.787"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M322.631 578.904L278.513 607.736"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M271.923 597.788L245 616.481"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M278.513 607.736L261.631 619.523"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M459.882 541.39L496.47 527.512"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M465.782 551.972L500.863 538.665"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M496.47 527.512L555.337 502.418"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M500.863 538.665L559.73 513.571"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M555.337 502.418L614.203 480.683"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M559.73 513.571L617.09 492.342"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M614.203 480.683L661.648 474.219"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M617.09 492.343L664.911 485.879"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M322.82 721.799L330.79 741"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M333.112 715.716L337.756 726.869"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M330.79 741L379.05 728.643"
      stroke="#991B1E"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M337.756 726.869L376.226 717.047"
      stroke="#7AAFDF"
      stroke-width="0.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1082.4 780.172L1068.11 780.198L1068.13 790.288L1082.41 790.262L1082.4 780.172Z"
      fill="#0A0000"
    />
    <path
      d="M1061.5 1066H932"
      stroke="#F06730"
    />
    <path
      d="M1054.5 1005.5H1062.5L1062 1083"
      stroke="#333CF6"
    />
    <path
      id="eflinsan-1"
      d="M806.958 998.935L753.962 999.558L754.009 1003.56L807.005 1002.93L806.958 998.935Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M859.954 998.312L806.958 998.935L807.005 1002.93L860.001 1002.31L859.954 998.312Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M912.951 997.689L859.955 998.312L860.002 1002.31L912.998 1001.69L912.951 997.689Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M965.947 997.066L912.951 997.689L912.998 1001.69L965.994 1001.07L965.947 997.066Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1018.94 996.442L965.948 997.065L965.995 1001.06L1018.99 1000.44L1018.94 996.442Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M807.005 1002.94L754.009 1003.56L754.056 1007.56L807.052 1006.94L807.005 1002.94Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M860.002 1002.31L807.006 1002.93L807.053 1006.93L860.049 1006.31L860.002 1002.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M912.998 1001.69L860.002 1002.31L860.049 1006.31L913.045 1005.69L912.998 1001.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M965.994 1001.07L912.998 1001.69L913.045 1005.69L966.041 1005.07L965.994 1001.07Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1018.99 1000.45L965.995 1001.07L966.042 1005.07L1019.04 1004.45L1018.99 1000.45Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M806.996 985.493L754 986.116L754.047 990.116L807.043 989.493L806.996 985.493Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M859.992 984.87L806.996 985.493L807.043 989.493L860.039 988.869L859.992 984.87Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M912.989 984.247L859.993 984.87L860.04 988.87L913.036 988.246L912.989 984.247Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M965.985 983.623L912.989 984.246L913.036 988.246L966.032 987.622L965.985 983.623Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1018.98 983L965.985 983.623L966.032 987.623L1019.03 986.999L1018.98 983Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M807.043 989.493L754.047 990.116L754.094 994.116L807.09 993.493L807.043 989.493Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M860.039 988.87L807.043 989.493L807.09 993.493L860.086 992.869L860.039 988.87Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M913.036 988.247L860.04 988.87L860.087 992.87L913.083 992.246L913.036 988.247Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M966.032 987.623L913.036 988.246L913.083 992.246L966.079 991.622L966.032 987.623Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1019.03 987L966.032 987.623L966.079 991.623L1019.08 990.999L1019.03 987Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1162 980.493L1109 981.116L1109.05 985.116L1162.04 984.493L1162 980.493Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1215 979.87L1162 980.493L1162.05 984.493L1215.04 983.869L1215 979.87Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1267.99 979.247L1214.99 979.87L1215.04 983.87L1268.03 983.246L1267.99 979.247Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1320.99 978.623L1267.99 979.246L1268.04 983.246L1321.03 982.622L1320.99 978.623Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1373.99 978L1320.99 978.623L1321.04 982.623L1374.03 981.999L1373.99 978Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1162.05 984.493L1109.05 985.116L1109.1 989.116L1162.09 988.493L1162.05 984.493Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1215.04 983.87L1162.04 984.493L1162.09 988.493L1215.08 987.869L1215.04 983.87Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1268.04 983.247L1215.04 983.87L1215.09 987.87L1268.08 987.246L1268.04 983.247Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1321.04 982.623L1268.04 983.246L1268.09 987.246L1321.08 986.622L1321.04 982.623Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1374.03 982L1321.03 982.623L1321.08 986.623L1374.07 985.999L1374.03 982Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1162 994.493L1109 995.116L1109.05 999.116L1162.04 998.493L1162 994.493Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1215 993.87L1162 994.493L1162.05 998.493L1215.04 997.869L1215 993.87Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1267.99 993.247L1214.99 993.87L1215.04 997.87L1268.03 997.246L1267.99 993.247Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1320.99 992.623L1267.99 993.246L1268.04 997.246L1321.03 996.622L1320.99 992.623Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1373.99 992L1320.99 992.623L1321.04 996.623L1374.03 995.999L1373.99 992Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1162.05 998.493L1109.05 999.116L1109.1 1003.12L1162.09 1002.49L1162.05 998.493Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1215.04 997.87L1162.04 998.493L1162.09 1002.49L1215.08 1001.87L1215.04 997.87Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1268.04 997.247L1215.04 997.87L1215.09 1001.87L1268.08 1001.25L1268.04 997.247Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1321.04 996.623L1268.04 997.246L1268.09 1001.25L1321.08 1000.62L1321.04 996.623Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1374.03 996L1321.03 996.623L1321.08 1000.62L1374.07 999.999L1374.03 996Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M846.996 1100.15L794 1100.76L794.046 1104.76L847.043 1104.15L846.996 1100.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M899.992 1099.54L846.996 1100.15L847.042 1104.15L900.039 1103.54L899.992 1099.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M952.989 1098.92L899.993 1099.53L900.039 1103.53L953.036 1102.92L952.989 1098.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1005.99 1098.31L952.989 1098.92L953.035 1102.92L1006.03 1102.31L1005.99 1098.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1058.99 1097.69L1005.99 1098.3L1006.04 1102.3L1059.03 1101.69L1058.99 1097.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1111.98 1097.08L1058.98 1097.69L1059.03 1101.69L1112.02 1101.08L1111.98 1097.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1164.98 1096.46L1111.98 1097.07L1112.03 1101.07L1165.02 1100.46L1164.98 1096.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1217.98 1095.85L1164.98 1096.46L1165.03 1100.46L1218.02 1099.85L1217.98 1095.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1270.97 1095.23L1217.97 1095.84L1218.02 1099.84L1271.01 1099.23L1270.97 1095.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1323.97 1094.62L1270.97 1095.23L1271.02 1099.23L1324.01 1098.62L1323.97 1094.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1376.96 1094L1323.96 1094.61L1324.01 1098.61L1377 1098L1376.96 1094Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M847.042 1104.15L794.046 1104.76L794.092 1108.76L847.089 1108.15L847.042 1104.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M900.039 1103.54L847.043 1104.15L847.089 1108.15L900.086 1107.54L900.039 1103.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M953.035 1102.92L900.039 1103.53L900.085 1107.53L953.082 1106.92L953.035 1102.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1006.03 1102.31L953.036 1102.92L953.082 1106.92L1006.08 1106.31L1006.03 1102.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1059.03 1101.69L1006.03 1102.3L1006.08 1106.3L1059.07 1105.69L1059.03 1101.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1112.03 1101.08L1059.03 1101.69L1059.08 1105.69L1112.07 1105.08L1112.03 1101.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1165.03 1100.46L1112.03 1101.07L1112.08 1105.07L1165.07 1104.46L1165.03 1100.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1218.02 1099.85L1165.02 1100.46L1165.07 1104.46L1218.06 1103.85L1218.02 1099.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1271.02 1099.23L1218.02 1099.84L1218.07 1103.84L1271.06 1103.23L1271.02 1099.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1324.01 1098.62L1271.01 1099.23L1271.06 1103.23L1324.05 1102.62L1324.01 1098.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1377.01 1098L1324.01 1098.61L1324.06 1102.61L1377.05 1102L1377.01 1098Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M846.996 1085.15L794 1085.76L794.046 1089.76L847.043 1089.15L846.996 1085.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M899.992 1084.54L846.996 1085.15L847.042 1089.15L900.039 1088.54L899.992 1084.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M952.989 1083.92L899.993 1084.53L900.039 1088.53L953.036 1087.92L952.989 1083.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1005.99 1083.31L952.989 1083.92L953.035 1087.92L1006.03 1087.31L1005.99 1083.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1058.99 1082.69L1005.99 1083.3L1006.04 1087.3L1059.03 1086.69L1058.99 1082.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1111.98 1082.08L1058.98 1082.69L1059.03 1086.69L1112.02 1086.08L1111.98 1082.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1164.98 1081.46L1111.98 1082.07L1112.03 1086.07L1165.02 1085.46L1164.98 1081.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1217.98 1080.85L1164.98 1081.46L1165.03 1085.46L1218.02 1084.85L1217.98 1080.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1270.97 1080.23L1217.97 1080.84L1218.02 1084.84L1271.01 1084.23L1270.97 1080.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1323.97 1079.62L1270.97 1080.23L1271.02 1084.23L1324.01 1083.62L1323.97 1079.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1376.96 1079L1323.96 1079.61L1324.01 1083.61L1377 1083L1376.96 1079Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M847.042 1089.15L794.046 1089.76L794.092 1093.76L847.089 1093.15L847.042 1089.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M900.039 1088.54L847.043 1089.15L847.089 1093.15L900.086 1092.54L900.039 1088.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M953.035 1087.92L900.039 1088.53L900.085 1092.53L953.082 1091.92L953.035 1087.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1006.03 1087.31L953.036 1087.92L953.082 1091.92L1006.08 1091.31L1006.03 1087.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1059.03 1086.69L1006.03 1087.3L1006.08 1091.3L1059.07 1090.69L1059.03 1086.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1112.03 1086.08L1059.03 1086.69L1059.08 1090.69L1112.07 1090.08L1112.03 1086.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1165.03 1085.46L1112.03 1086.07L1112.08 1090.07L1165.07 1089.46L1165.03 1085.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1218.02 1084.85L1165.02 1085.46L1165.07 1089.46L1218.06 1088.85L1218.02 1084.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1271.02 1084.23L1218.02 1084.84L1218.07 1088.84L1271.06 1088.23L1271.02 1084.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1324.01 1083.62L1271.01 1084.23L1271.06 1088.23L1324.05 1087.62L1324.01 1083.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1377.01 1083L1324.01 1083.61L1324.06 1087.61L1377.05 1087L1377.01 1083Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M846.996 1070.15L794 1070.76L794.046 1074.76L847.043 1074.15L846.996 1070.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M899.992 1069.54L846.996 1070.15L847.042 1074.15L900.039 1073.54L899.992 1069.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M952.989 1068.92L899.993 1069.53L900.039 1073.53L953.036 1072.92L952.989 1068.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1005.99 1068.31L952.989 1068.92L953.035 1072.92L1006.03 1072.31L1005.99 1068.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1058.99 1067.69L1005.99 1068.3L1006.04 1072.3L1059.03 1071.69L1058.99 1067.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1111.98 1067.08L1058.98 1067.69L1059.03 1071.69L1112.02 1071.08L1111.98 1067.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1164.98 1066.46L1111.98 1067.07L1112.03 1071.07L1165.02 1070.46L1164.98 1066.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1217.98 1065.85L1164.98 1066.46L1165.03 1070.46L1218.02 1069.85L1217.98 1065.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1270.97 1065.23L1217.97 1065.84L1218.02 1069.84L1271.01 1069.23L1270.97 1065.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1323.97 1064.62L1270.97 1065.23L1271.02 1069.23L1324.01 1068.62L1323.97 1064.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1376.96 1064L1323.96 1064.61L1324.01 1068.61L1377 1068L1376.96 1064Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M847.042 1074.15L794.046 1074.76L794.092 1078.76L847.089 1078.15L847.042 1074.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M900.039 1073.54L847.043 1074.15L847.089 1078.15L900.086 1077.54L900.039 1073.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M953.035 1072.92L900.039 1073.53L900.085 1077.53L953.082 1076.92L953.035 1072.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1006.03 1072.31L953.036 1072.92L953.082 1076.92L1006.08 1076.31L1006.03 1072.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1059.03 1071.69L1006.03 1072.3L1006.08 1076.3L1059.07 1075.69L1059.03 1071.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1112.03 1071.08L1059.03 1071.69L1059.08 1075.69L1112.07 1075.08L1112.03 1071.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1165.03 1070.46L1112.03 1071.07L1112.08 1075.07L1165.07 1074.46L1165.03 1070.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1218.02 1069.85L1165.02 1070.46L1165.07 1074.46L1218.06 1073.85L1218.02 1069.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1271.02 1069.23L1218.02 1069.84L1218.07 1073.84L1271.06 1073.23L1271.02 1069.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1324.01 1068.62L1271.01 1069.23L1271.06 1073.23L1324.05 1072.62L1324.01 1068.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1377.01 1068L1324.01 1068.61L1324.06 1072.61L1377.05 1072L1377.01 1068Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M846.996 1056.15L794 1056.76L794.046 1060.76L847.043 1060.15L846.996 1056.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M899.992 1055.54L846.996 1056.15L847.042 1060.15L900.039 1059.54L899.992 1055.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M952.989 1054.92L899.993 1055.53L900.039 1059.53L953.036 1058.92L952.989 1054.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1005.99 1054.31L952.989 1054.92L953.035 1058.92L1006.03 1058.31L1005.99 1054.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1058.99 1053.69L1005.99 1054.3L1006.04 1058.3L1059.03 1057.69L1058.99 1053.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1111.98 1053.08L1058.98 1053.69L1059.03 1057.69L1112.02 1057.08L1111.98 1053.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1164.98 1052.46L1111.98 1053.07L1112.03 1057.07L1165.02 1056.46L1164.98 1052.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1217.98 1051.85L1164.98 1052.46L1165.03 1056.46L1218.02 1055.85L1217.98 1051.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1270.97 1051.23L1217.97 1051.84L1218.02 1055.84L1271.01 1055.23L1270.97 1051.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1323.97 1050.62L1270.97 1051.23L1271.02 1055.23L1324.01 1054.62L1323.97 1050.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1376.96 1050L1323.96 1050.61L1324.01 1054.61L1377 1054L1376.96 1050Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M847.042 1060.15L794.046 1060.76L794.092 1064.76L847.089 1064.15L847.042 1060.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M900.039 1059.54L847.043 1060.15L847.089 1064.15L900.086 1063.54L900.039 1059.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M953.035 1058.92L900.039 1059.53L900.085 1063.53L953.082 1062.92L953.035 1058.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1006.03 1058.31L953.036 1058.92L953.082 1062.92L1006.08 1062.31L1006.03 1058.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1059.03 1057.69L1006.03 1058.3L1006.08 1062.3L1059.07 1061.69L1059.03 1057.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1112.03 1057.08L1059.03 1057.69L1059.08 1061.69L1112.07 1061.08L1112.03 1057.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1165.03 1056.46L1112.03 1057.07L1112.08 1061.07L1165.07 1060.46L1165.03 1056.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1218.02 1055.85L1165.02 1056.46L1165.07 1060.46L1218.06 1059.85L1218.02 1055.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1271.02 1055.23L1218.02 1055.84L1218.07 1059.84L1271.06 1059.23L1271.02 1055.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1324.01 1054.62L1271.01 1055.23L1271.06 1059.23L1324.05 1058.62L1324.01 1054.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1377.01 1054L1324.01 1054.61L1324.06 1058.61L1377.05 1058L1377.01 1054Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M845.996 1042.15L793 1042.76L793.046 1046.76L846.043 1046.15L845.996 1042.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M898.992 1041.54L845.996 1042.15L846.042 1046.15L899.039 1045.54L898.992 1041.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M951.989 1040.92L898.993 1041.53L899.039 1045.53L952.036 1044.92L951.989 1040.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1004.99 1040.31L951.989 1040.92L952.035 1044.92L1005.03 1044.31L1004.99 1040.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1057.99 1039.69L1004.99 1040.3L1005.04 1044.3L1058.03 1043.69L1057.99 1039.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1110.98 1039.08L1057.98 1039.69L1058.03 1043.69L1111.02 1043.08L1110.98 1039.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1163.98 1038.46L1110.98 1039.07L1111.03 1043.07L1164.02 1042.46L1163.98 1038.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1216.98 1037.85L1163.98 1038.46L1164.03 1042.46L1217.02 1041.85L1216.98 1037.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1269.97 1037.23L1216.97 1037.84L1217.02 1041.84L1270.01 1041.23L1269.97 1037.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1322.97 1036.62L1269.97 1037.23L1270.02 1041.23L1323.01 1040.62L1322.97 1036.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1375.96 1036L1322.96 1036.61L1323.01 1040.61L1376 1040L1375.96 1036Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M846.042 1046.15L793.046 1046.76L793.092 1050.76L846.089 1050.15L846.042 1046.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M899.039 1045.54L846.043 1046.15L846.089 1050.15L899.086 1049.54L899.039 1045.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M952.035 1044.92L899.039 1045.53L899.085 1049.53L952.082 1048.92L952.035 1044.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1005.03 1044.31L952.036 1044.92L952.082 1048.92L1005.08 1048.31L1005.03 1044.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1058.03 1043.69L1005.03 1044.3L1005.08 1048.3L1058.07 1047.69L1058.03 1043.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1111.03 1043.08L1058.03 1043.69L1058.08 1047.69L1111.07 1047.08L1111.03 1043.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1164.03 1042.46L1111.03 1043.07L1111.08 1047.07L1164.07 1046.46L1164.03 1042.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1217.02 1041.85L1164.02 1042.46L1164.07 1046.46L1217.06 1045.85L1217.02 1041.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1270.02 1041.23L1217.02 1041.84L1217.07 1045.84L1270.06 1045.23L1270.02 1041.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1323.01 1040.62L1270.01 1041.23L1270.06 1045.23L1323.05 1044.62L1323.01 1040.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1376.01 1040L1323.01 1040.61L1323.06 1044.61L1376.05 1044L1376.01 1040Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M846.996 1027.15L794 1027.76L794.046 1031.76L847.043 1031.15L846.996 1027.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M899.992 1026.54L846.996 1027.15L847.042 1031.15L900.039 1030.54L899.992 1026.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M952.989 1025.92L899.993 1026.53L900.039 1030.53L953.036 1029.92L952.989 1025.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1005.99 1025.31L952.989 1025.92L953.035 1029.92L1006.03 1029.31L1005.99 1025.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1058.99 1024.69L1005.99 1025.3L1006.04 1029.3L1059.03 1028.69L1058.99 1024.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1111.98 1024.08L1058.98 1024.69L1059.03 1028.69L1112.02 1028.08L1111.98 1024.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1164.98 1023.46L1111.98 1024.07L1112.03 1028.07L1165.02 1027.46L1164.98 1023.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1217.98 1022.85L1164.98 1023.46L1165.03 1027.46L1218.02 1026.85L1217.98 1022.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1270.97 1022.23L1217.97 1022.84L1218.02 1026.84L1271.01 1026.23L1270.97 1022.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1323.97 1021.62L1270.97 1022.23L1271.02 1026.23L1324.01 1025.62L1323.97 1021.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1376.96 1021L1323.96 1021.61L1324.01 1025.61L1377 1025L1376.96 1021Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M847.042 1031.15L794.046 1031.76L794.092 1035.76L847.089 1035.15L847.042 1031.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M900.039 1030.54L847.043 1031.15L847.089 1035.15L900.086 1034.54L900.039 1030.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M953.035 1029.92L900.039 1030.53L900.085 1034.53L953.082 1033.92L953.035 1029.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1006.03 1029.31L953.036 1029.92L953.082 1033.92L1006.08 1033.31L1006.03 1029.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1059.03 1028.69L1006.03 1029.3L1006.08 1033.3L1059.07 1032.69L1059.03 1028.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1112.03 1028.08L1059.03 1028.69L1059.08 1032.69L1112.07 1032.08L1112.03 1028.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1165.03 1027.46L1112.03 1028.07L1112.08 1032.07L1165.07 1031.46L1165.03 1027.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1218.02 1026.85L1165.02 1027.46L1165.07 1031.46L1218.06 1030.85L1218.02 1026.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1271.02 1026.23L1218.02 1026.84L1218.07 1030.84L1271.06 1030.23L1271.02 1026.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1324.01 1025.62L1271.01 1026.23L1271.06 1030.23L1324.05 1029.62L1324.01 1025.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1377.01 1025L1324.01 1025.61L1324.06 1029.61L1377.05 1029L1377.01 1025Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M846.996 1013.15L794 1013.76L794.046 1017.76L847.043 1017.15L846.996 1013.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M899.992 1012.54L846.996 1013.15L847.042 1017.15L900.039 1016.54L899.992 1012.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M952.989 1011.92L899.993 1012.53L900.039 1016.53L953.036 1015.92L952.989 1011.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1005.99 1011.31L952.989 1011.92L953.035 1015.92L1006.03 1015.31L1005.99 1011.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1058.99 1010.69L1005.99 1011.3L1006.04 1015.3L1059.03 1014.69L1058.99 1010.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1111.98 1010.08L1058.98 1010.69L1059.03 1014.69L1112.02 1014.08L1111.98 1010.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1164.98 1009.46L1111.98 1010.07L1112.03 1014.07L1165.02 1013.46L1164.98 1009.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1217.98 1008.85L1164.98 1009.46L1165.03 1013.46L1218.02 1012.85L1217.98 1008.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1270.97 1008.23L1217.97 1008.84L1218.02 1012.84L1271.01 1012.23L1270.97 1008.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1323.97 1007.62L1270.97 1008.23L1271.02 1012.23L1324.01 1011.62L1323.97 1007.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1376.96 1007L1323.96 1007.61L1324.01 1011.61L1377 1011L1376.96 1007Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M847.042 1017.15L794.046 1017.76L794.092 1021.76L847.089 1021.15L847.042 1017.15Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M900.039 1016.54L847.043 1017.15L847.089 1021.15L900.086 1020.54L900.039 1016.54Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M953.035 1015.92L900.039 1016.53L900.085 1020.53L953.082 1019.92L953.035 1015.92Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1006.03 1015.31L953.036 1015.92L953.082 1019.92L1006.08 1019.31L1006.03 1015.31Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1059.03 1014.69L1006.03 1015.3L1006.08 1019.3L1059.07 1018.69L1059.03 1014.69Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1112.03 1014.08L1059.03 1014.69L1059.08 1018.69L1112.07 1018.08L1112.03 1014.08Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1165.03 1013.46L1112.03 1014.07L1112.08 1018.07L1165.07 1017.46L1165.03 1013.46Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1218.02 1012.85L1165.02 1013.46L1165.07 1017.46L1218.06 1016.85L1218.02 1012.85Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1271.02 1012.23L1218.02 1012.84L1218.07 1016.84L1271.06 1016.23L1271.02 1012.23Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1324.01 1011.62L1271.01 1012.23L1271.06 1016.23L1324.05 1015.62L1324.01 1011.62Z"
      fill="#2CFFFE"
    />
    <path
      id="eflinsan-1"
      d="M1377.01 1011L1324.01 1011.61L1324.06 1015.61L1377.05 1015L1377.01 1011Z"
      fill="#2CFFFE"
    />
    <path
      d="M1383.41 1033.5L1382.8 1006.22M796.5 1113.5L817.155 1113.22L796.5 1113.5ZM823.725 1113.13L851 1112.76L823.725 1113.13ZM857 1112.68L884.656 1112.3L857 1112.68ZM891 1112.22L918.5 1111.84L891 1112.22ZM924.731 1111.76L952 1111.39L924.731 1111.76ZM958.5 1111.3L986 1110.92L958.5 1111.3ZM992 1110.84L1019.5 1110.47L992 1110.84ZM1026 1110.38L1053.07 1110.01L1026 1110.38ZM1060 1109.92L1087 1109.55L1060 1109.92ZM1093.33 1109.46L1120.72 1109.09L1093.33 1109.46ZM1127 1109.01L1154.14 1108.64L1127 1109.01ZM1160.72 1108.55L1187.96 1108.18L1160.72 1108.55ZM1194.59 1108.09L1221.87 1107.72L1194.59 1108.09ZM1228 1107.63L1255.5 1107.26L1228 1107.63ZM1261.78 1107.18L1289 1106.8L1261.78 1107.18ZM1295.69 1106.71L1323 1106.34L1295.69 1106.71ZM1329.15 1106.26L1356.76 1105.88L1329.15 1106.26ZM1363 1105.8L1385 1105.5L1384.29 1073.4L1363 1105.8ZM1364.5 970H1382L1382.66 1000L1364.5 970ZM1384.15 1067.14L1383.55 1040L1384.15 1067.14Z"
      stroke="#29FD2E"
    />
    <path
      d="M894 1008H881V1013H894V1008Z"
      fill="#C4C4C4"
    />
    <path
      d="M894.5 1010.5L1256 1005.5"
      stroke="#F06730"
    />
    <path
      d="M913 1008H900V1013H913V1008Z"
      fill="#C4C4C4"
    />
    <path
      d="M1053.5 990.5H1048.5V1008.5H1053.5V990.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1064.5 990.5H1059.5V1008.5H1064.5V990.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1255.5 1078.5H1062.5"
      stroke="#F06730"
    />
    <path
      d="M1064 1077H1060V1081H1064V1077Z"
      fill="#0A0000"
    />
    <path
      d="M1083 1050H1075V1054H1083V1050Z"
      fill="#C4C4C4"
    />
    <path
      d="M1093 1050H1085V1054H1093V1050Z"
      fill="#C4C4C4"
    />
    <path
      d="M1086.5 1051.5L1062.5 1051"
      stroke="#F06730"
    />
    <path
      d="M1064 1049H1060V1053H1064V1049Z"
      fill="#0A0000"
    />
    <path
      d="M1064 1064H1060V1068H1064V1064Z"
      fill="#0A0000"
    />
    <path
      d="M933 1064H924V1068H933V1064Z"
      fill="#C4C4C4"
    />
    <path
      d="M944 1064H935V1068H944V1064Z"
      fill="#C4C4C4"
    />
    <path
      d="M1252 1004H1244V1009H1252V1004Z"
      fill="#C4C4C4"
    />
    <path
      d="M1264 1004H1256V1009H1264V1004Z"
      fill="#C4C4C4"
    />
    <path
      d="M1252 1077H1244V1081H1252V1077Z"
      fill="#C4C4C4"
    />
    <path
      d="M1263 1077H1255V1081H1263V1077Z"
      fill="#C4C4C4"
    />
    <path
      d="M966 1204.5L1337 1202"
      stroke="#F06730"
    />
    <path
      d="M913.5 1147.5L1269 1143H1277.5"
      stroke="#F06730"
    />
    <path
      d="M1087.5 1143.5C1087.9 1143.9 1093 1143.67 1095.5 1143.5L1095 1206"
      stroke="#333CF6"
    />
    <path
      d="M775.5 1116.5L1051.5 1114L1084.5 1127.5V1130"
      stroke="#2CFFFE"
    />
    <path
      id="elvinsan-1"
      d="M842.943 1122.36L789.951 1123.27L790.02 1127.27L843.012 1126.36L842.943 1122.36Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M895.935 1121.45L842.943 1122.36L843.012 1126.36L896.004 1125.45L895.935 1121.45Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M948.927 1120.55L895.935 1121.46L896.004 1125.46L948.996 1124.55L948.927 1120.55Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1001.92 1119.64L948.927 1120.55L948.996 1124.55L1001.99 1123.64L1001.92 1119.64Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1054.91 1118.73L1001.92 1119.64L1001.99 1123.64L1054.98 1122.73L1054.91 1118.73Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M843.011 1126.36L790.019 1127.27L790.088 1131.27L843.08 1130.36L843.011 1126.36Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M896.004 1125.45L843.012 1126.36L843.081 1130.36L896.073 1129.45L896.004 1125.45Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M948.996 1124.54L896.004 1125.45L896.073 1129.45L949.065 1128.54L948.996 1124.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1001.99 1123.64L948.996 1124.55L949.065 1128.55L1002.06 1127.64L1001.99 1123.64Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1054.98 1122.73L1001.99 1123.64L1002.06 1127.64L1055.05 1126.73L1054.98 1122.73Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M842.992 1136.64L790 1137.55L790.069 1141.55L843.061 1140.64L842.992 1136.64Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M895.984 1135.73L842.992 1136.64L843.061 1140.64L896.053 1139.73L895.984 1135.73Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M948.976 1134.82L895.984 1135.73L896.053 1139.73L949.045 1138.82L948.976 1134.82Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1001.97 1133.91L948.977 1134.82L949.046 1138.82L1002.04 1137.91L1001.97 1133.91Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1054.96 1133L1001.97 1133.91L1002.04 1137.91L1055.03 1137L1054.96 1133Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M843.061 1140.64L790.069 1141.55L790.138 1145.55L843.13 1144.64L843.061 1140.64Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M896.053 1139.73L843.061 1140.64L843.13 1144.64L896.122 1143.73L896.053 1139.73Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M949.045 1138.82L896.053 1139.73L896.122 1143.73L949.114 1142.82L949.045 1138.82Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1002.04 1137.91L949.045 1138.82L949.114 1142.82L1002.11 1141.91L1002.04 1137.91Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1055.03 1137L1002.04 1137.91L1002.11 1141.91L1055.1 1141L1055.03 1137Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1194 1117.5L1141 1118.12L1141.05 1122.12L1194.04 1121.5L1194 1117.5Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1247 1116.87L1194 1117.49L1194.05 1121.49L1247.04 1120.87L1247 1116.87Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1299.99 1116.25L1246.99 1116.87L1247.04 1120.87L1300.03 1120.25L1299.99 1116.25Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1352.99 1115.63L1299.99 1116.25L1300.04 1120.25L1353.03 1119.63L1352.99 1115.63Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1405.99 1115L1352.99 1115.62L1353.04 1119.62L1406.03 1119L1405.99 1115Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1194.05 1121.5L1141.05 1122.12L1141.1 1126.12L1194.09 1125.5L1194.05 1121.5Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1247.04 1120.87L1194.04 1121.49L1194.09 1125.49L1247.08 1124.87L1247.04 1120.87Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1300.04 1120.25L1247.04 1120.87L1247.09 1124.87L1300.08 1124.25L1300.04 1120.25Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1353.04 1119.63L1300.04 1120.25L1300.09 1124.25L1353.08 1123.63L1353.04 1119.63Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1406.03 1119L1353.03 1119.62L1353.08 1123.62L1406.07 1123L1406.03 1119Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1194 1131.5L1141 1132.12L1141.05 1136.12L1194.04 1135.5L1194 1131.5Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1247 1130.87L1194 1131.49L1194.05 1135.49L1247.04 1134.87L1247 1130.87Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1299.99 1130.25L1246.99 1130.87L1247.04 1134.87L1300.03 1134.25L1299.99 1130.25Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1352.99 1129.63L1299.99 1130.25L1300.04 1134.25L1353.03 1133.63L1352.99 1129.63Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1405.99 1129L1352.99 1129.62L1353.04 1133.62L1406.03 1133L1405.99 1129Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1194.05 1135.5L1141.05 1136.12L1141.1 1140.12L1194.09 1139.5L1194.05 1135.5Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1247.04 1134.87L1194.04 1135.49L1194.09 1139.49L1247.08 1138.87L1247.04 1134.87Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1300.04 1134.25L1247.04 1134.87L1247.09 1138.87L1300.08 1138.25L1300.04 1134.25Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1353.04 1133.63L1300.04 1134.25L1300.09 1138.25L1353.08 1137.63L1353.04 1133.63Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1406.03 1133L1353.03 1133.62L1353.08 1137.62L1406.07 1137L1406.03 1133Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M846.996 1150.15L794 1150.76L794.046 1154.76L847.043 1154.15L846.996 1150.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M899.992 1149.54L846.996 1150.15L847.042 1154.15L900.039 1153.54L899.992 1149.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M952.989 1148.92L899.993 1149.53L900.039 1153.53L953.036 1152.92L952.989 1148.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1005.99 1148.31L952.989 1148.92L953.035 1152.92L1006.03 1152.31L1005.99 1148.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1058.99 1147.69L1005.99 1148.3L1006.04 1152.3L1059.03 1151.69L1058.99 1147.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1111.98 1147.08L1058.98 1147.69L1059.03 1151.69L1112.02 1151.08L1111.98 1147.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1164.98 1146.46L1111.98 1147.07L1112.03 1151.07L1165.02 1150.46L1164.98 1146.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1217.98 1145.85L1164.98 1146.46L1165.03 1150.46L1218.02 1149.85L1217.98 1145.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1270.97 1145.23L1217.97 1145.84L1218.02 1149.84L1271.01 1149.23L1270.97 1145.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1323.97 1144.62L1270.97 1145.23L1271.02 1149.23L1324.01 1148.62L1323.97 1144.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1376.96 1144L1323.96 1144.61L1324.01 1148.61L1377 1148L1376.96 1144Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M847.042 1154.15L794.046 1154.76L794.092 1158.76L847.089 1158.15L847.042 1154.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M900.039 1153.54L847.043 1154.15L847.089 1158.15L900.086 1157.54L900.039 1153.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M953.035 1152.92L900.039 1153.53L900.085 1157.53L953.082 1156.92L953.035 1152.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1006.03 1152.31L953.036 1152.92L953.082 1156.92L1006.08 1156.31L1006.03 1152.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1059.03 1151.69L1006.03 1152.3L1006.08 1156.3L1059.07 1155.69L1059.03 1151.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1112.03 1151.08L1059.03 1151.69L1059.08 1155.69L1112.07 1155.08L1112.03 1151.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1165.03 1150.46L1112.03 1151.07L1112.08 1155.07L1165.07 1154.46L1165.03 1150.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1218.02 1149.85L1165.02 1150.46L1165.07 1154.46L1218.06 1153.85L1218.02 1149.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1271.02 1149.23L1218.02 1149.84L1218.07 1153.84L1271.06 1153.23L1271.02 1149.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1324.01 1148.62L1271.01 1149.23L1271.06 1153.23L1324.05 1152.62L1324.01 1148.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1377.01 1148L1324.01 1148.61L1324.06 1152.61L1377.05 1152L1377.01 1148Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M864.996 1165.15L812 1165.76L812.046 1169.76L865.043 1169.15L864.996 1165.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M917.992 1164.54L864.996 1165.15L865.042 1169.15L918.039 1168.54L917.992 1164.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M970.989 1163.92L917.993 1164.53L918.039 1168.53L971.036 1167.92L970.989 1163.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1023.99 1163.31L970.989 1163.92L971.035 1167.92L1024.03 1167.31L1023.99 1163.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1076.99 1162.69L1023.99 1163.3L1024.04 1167.3L1077.03 1166.69L1076.99 1162.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1129.98 1162.08L1076.98 1162.69L1077.03 1166.69L1130.02 1166.08L1129.98 1162.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1182.98 1161.46L1129.98 1162.07L1130.03 1166.07L1183.02 1165.46L1182.98 1161.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1235.98 1160.85L1182.98 1161.46L1183.03 1165.46L1236.02 1164.85L1235.98 1160.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1288.97 1160.23L1235.97 1160.84L1236.02 1164.84L1289.01 1164.23L1288.97 1160.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1341.97 1159.62L1288.97 1160.23L1289.02 1164.23L1342.01 1163.62L1341.97 1159.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1394.96 1159L1341.96 1159.61L1342.01 1163.61L1395 1163L1394.96 1159Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M865.042 1169.15L812.046 1169.76L812.092 1173.76L865.089 1173.15L865.042 1169.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M918.039 1168.54L865.043 1169.15L865.089 1173.15L918.086 1172.54L918.039 1168.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M971.035 1167.92L918.039 1168.53L918.085 1172.53L971.082 1171.92L971.035 1167.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1024.03 1167.31L971.036 1167.92L971.082 1171.92L1024.08 1171.31L1024.03 1167.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1077.03 1166.69L1024.03 1167.3L1024.08 1171.3L1077.07 1170.69L1077.03 1166.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1130.03 1166.08L1077.03 1166.69L1077.08 1170.69L1130.07 1170.08L1130.03 1166.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1183.03 1165.46L1130.03 1166.07L1130.08 1170.07L1183.07 1169.46L1183.03 1165.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1236.02 1164.85L1183.02 1165.46L1183.07 1169.46L1236.06 1168.85L1236.02 1164.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1289.02 1164.23L1236.02 1164.84L1236.07 1168.84L1289.06 1168.23L1289.02 1164.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1342.01 1163.62L1289.01 1164.23L1289.06 1168.23L1342.05 1167.62L1342.01 1163.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1395.01 1163L1342.01 1163.61L1342.06 1167.61L1395.05 1167L1395.01 1163Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M879.996 1180.15L827 1180.76L827.046 1184.76L880.043 1184.15L879.996 1180.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M932.992 1179.54L879.996 1180.15L880.042 1184.15L933.039 1183.54L932.992 1179.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M985.989 1178.92L932.993 1179.53L933.039 1183.53L986.036 1182.92L985.989 1178.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1038.99 1178.31L985.989 1178.92L986.035 1182.92L1039.03 1182.31L1038.99 1178.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1091.99 1177.69L1038.99 1178.3L1039.04 1182.3L1092.03 1181.69L1091.99 1177.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1144.98 1177.08L1091.98 1177.69L1092.03 1181.69L1145.02 1181.08L1144.98 1177.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1197.98 1176.46L1144.98 1177.07L1145.03 1181.07L1198.02 1180.46L1197.98 1176.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1250.98 1175.85L1197.98 1176.46L1198.03 1180.46L1251.02 1179.85L1250.98 1175.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1303.97 1175.23L1250.97 1175.84L1251.02 1179.84L1304.01 1179.23L1303.97 1175.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1356.97 1174.62L1303.97 1175.23L1304.02 1179.23L1357.01 1178.62L1356.97 1174.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1409.96 1174L1356.96 1174.61L1357.01 1178.61L1410 1178L1409.96 1174Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M880.042 1184.15L827.046 1184.76L827.092 1188.76L880.089 1188.15L880.042 1184.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M933.039 1183.54L880.043 1184.15L880.089 1188.15L933.086 1187.54L933.039 1183.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M986.035 1182.92L933.039 1183.53L933.085 1187.53L986.082 1186.92L986.035 1182.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1039.03 1182.31L986.036 1182.92L986.082 1186.92L1039.08 1186.31L1039.03 1182.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1092.03 1181.69L1039.03 1182.3L1039.08 1186.3L1092.07 1185.69L1092.03 1181.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1145.03 1181.08L1092.03 1181.69L1092.08 1185.69L1145.07 1185.08L1145.03 1181.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1198.03 1180.46L1145.03 1181.07L1145.08 1185.07L1198.07 1184.46L1198.03 1180.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1251.02 1179.85L1198.02 1180.46L1198.07 1184.46L1251.06 1183.85L1251.02 1179.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1304.02 1179.23L1251.02 1179.84L1251.07 1183.84L1304.06 1183.23L1304.02 1179.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1357.01 1178.62L1304.01 1179.23L1304.06 1183.23L1357.05 1182.62L1357.01 1178.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1410.01 1178L1357.01 1178.61L1357.06 1182.61L1410.05 1182L1410.01 1178Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M898.996 1194.15L846 1194.76L846.046 1198.76L899.043 1198.15L898.996 1194.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M951.992 1193.54L898.996 1194.15L899.042 1198.15L952.039 1197.54L951.992 1193.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1004.99 1192.92L951.993 1193.53L952.039 1197.53L1005.04 1196.92L1004.99 1192.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1057.99 1192.31L1004.99 1192.92L1005.04 1196.92L1058.03 1196.31L1057.99 1192.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1110.99 1191.69L1057.99 1192.3L1058.04 1196.3L1111.03 1195.69L1110.99 1191.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1163.98 1191.08L1110.98 1191.69L1111.03 1195.69L1164.02 1195.08L1163.98 1191.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1216.98 1190.46L1163.98 1191.07L1164.03 1195.07L1217.02 1194.46L1216.98 1190.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1269.98 1189.85L1216.98 1190.46L1217.03 1194.46L1270.02 1193.85L1269.98 1189.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1322.97 1189.23L1269.97 1189.84L1270.02 1193.84L1323.01 1193.23L1322.97 1189.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1375.97 1188.62L1322.97 1189.23L1323.02 1193.23L1376.01 1192.62L1375.97 1188.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1428.96 1188L1375.96 1188.61L1376.01 1192.61L1429 1192L1428.96 1188Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M899.042 1198.15L846.046 1198.76L846.092 1202.76L899.089 1202.15L899.042 1198.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M952.039 1197.54L899.043 1198.15L899.089 1202.15L952.086 1201.54L952.039 1197.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1005.04 1196.92L952.039 1197.53L952.085 1201.53L1005.08 1200.92L1005.04 1196.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1058.04 1196.31L1005.04 1196.92L1005.09 1200.92L1058.08 1200.31L1058.04 1196.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1111.03 1195.69L1058.03 1196.3L1058.08 1200.3L1111.07 1199.69L1111.03 1195.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1164.03 1195.08L1111.03 1195.69L1111.08 1199.69L1164.07 1199.08L1164.03 1195.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1217.03 1194.46L1164.03 1195.07L1164.08 1199.07L1217.07 1198.46L1217.03 1194.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1270.02 1193.85L1217.02 1194.46L1217.07 1198.46L1270.06 1197.85L1270.02 1193.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1323.02 1193.23L1270.02 1193.84L1270.07 1197.84L1323.06 1197.23L1323.02 1193.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1376.01 1192.62L1323.01 1193.23L1323.06 1197.23L1376.05 1196.62L1376.01 1192.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1429.01 1192L1376.01 1192.61L1376.06 1196.61L1429.05 1196L1429.01 1192Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M904.996 1208.15L852 1208.76L852.046 1212.76L905.043 1212.15L904.996 1208.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M957.992 1207.54L904.996 1208.15L905.042 1212.15L958.039 1211.54L957.992 1207.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1010.99 1206.92L957.993 1207.53L958.039 1211.53L1011.04 1210.92L1010.99 1206.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1063.99 1206.31L1010.99 1206.92L1011.04 1210.92L1064.03 1210.31L1063.99 1206.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1116.99 1205.69L1063.99 1206.3L1064.04 1210.3L1117.03 1209.69L1116.99 1205.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1169.98 1205.08L1116.98 1205.69L1117.03 1209.69L1170.02 1209.08L1169.98 1205.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1222.98 1204.46L1169.98 1205.07L1170.03 1209.07L1223.02 1208.46L1222.98 1204.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1275.98 1203.85L1222.98 1204.46L1223.03 1208.46L1276.02 1207.85L1275.98 1203.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1328.97 1203.23L1275.97 1203.84L1276.02 1207.84L1329.01 1207.23L1328.97 1203.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1381.97 1202.62L1328.97 1203.23L1329.02 1207.23L1382.01 1206.62L1381.97 1202.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1434.96 1202L1381.96 1202.61L1382.01 1206.61L1435 1206L1434.96 1202Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M905.042 1212.15L852.046 1212.76L852.092 1216.76L905.089 1216.15L905.042 1212.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M958.039 1211.54L905.043 1212.15L905.089 1216.15L958.086 1215.54L958.039 1211.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1011.04 1210.92L958.039 1211.53L958.085 1215.53L1011.08 1214.92L1011.04 1210.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1064.04 1210.31L1011.04 1210.92L1011.09 1214.92L1064.08 1214.31L1064.04 1210.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1117.03 1209.69L1064.03 1210.3L1064.08 1214.3L1117.07 1213.69L1117.03 1209.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1170.03 1209.08L1117.03 1209.69L1117.08 1213.69L1170.07 1213.08L1170.03 1209.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1223.03 1208.46L1170.03 1209.07L1170.08 1213.07L1223.07 1212.46L1223.03 1208.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1276.02 1207.85L1223.02 1208.46L1223.07 1212.46L1276.06 1211.85L1276.02 1207.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1329.02 1207.23L1276.02 1207.84L1276.07 1211.84L1329.06 1211.23L1329.02 1207.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1382.01 1206.62L1329.01 1207.23L1329.06 1211.23L1382.05 1210.62L1382.01 1206.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1435.01 1206L1382.01 1206.61L1382.06 1210.61L1435.05 1210L1435.01 1206Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M922.996 1222.15L870 1222.76L870.046 1226.76L923.043 1226.15L922.996 1222.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M975.992 1221.54L922.996 1222.15L923.042 1226.15L976.039 1225.54L975.992 1221.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1028.99 1220.92L975.993 1221.53L976.039 1225.53L1029.04 1224.92L1028.99 1220.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1081.99 1220.31L1028.99 1220.92L1029.04 1224.92L1082.03 1224.31L1081.99 1220.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1134.99 1219.69L1081.99 1220.3L1082.04 1224.3L1135.03 1223.69L1134.99 1219.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1187.98 1219.08L1134.98 1219.69L1135.03 1223.69L1188.02 1223.08L1187.98 1219.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1240.98 1218.46L1187.98 1219.07L1188.03 1223.07L1241.02 1222.46L1240.98 1218.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1293.98 1217.85L1240.98 1218.46L1241.03 1222.46L1294.02 1221.85L1293.98 1217.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1346.97 1217.23L1293.97 1217.84L1294.02 1221.84L1347.01 1221.23L1346.97 1217.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1399.97 1216.62L1346.97 1217.23L1347.02 1221.23L1400.01 1220.62L1399.97 1216.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1452.96 1216L1399.96 1216.61L1400.01 1220.61L1453 1220L1452.96 1216Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M923.042 1226.15L870.046 1226.76L870.092 1230.76L923.089 1230.15L923.042 1226.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M976.039 1225.54L923.043 1226.15L923.089 1230.15L976.086 1229.54L976.039 1225.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1029.04 1224.92L976.039 1225.53L976.085 1229.53L1029.08 1228.92L1029.04 1224.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1082.04 1224.31L1029.04 1224.92L1029.09 1228.92L1082.08 1228.31L1082.04 1224.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1135.03 1223.69L1082.03 1224.3L1082.08 1228.3L1135.07 1227.69L1135.03 1223.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1188.03 1223.08L1135.03 1223.69L1135.08 1227.69L1188.07 1227.08L1188.03 1223.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1241.03 1222.46L1188.03 1223.07L1188.08 1227.07L1241.07 1226.46L1241.03 1222.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1294.02 1221.85L1241.02 1222.46L1241.07 1226.46L1294.06 1225.85L1294.02 1221.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1347.02 1221.23L1294.02 1221.84L1294.07 1225.84L1347.06 1225.23L1347.02 1221.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1400.01 1220.62L1347.01 1221.23L1347.06 1225.23L1400.05 1224.62L1400.01 1220.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1453.01 1220L1400.01 1220.61L1400.06 1224.61L1453.05 1224L1453.01 1220Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M938.996 1236.15L886 1236.76L886.046 1240.76L939.043 1240.15L938.996 1236.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M991.992 1235.54L938.996 1236.15L939.042 1240.15L992.039 1239.54L991.992 1235.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1044.99 1234.92L991.993 1235.53L992.039 1239.53L1045.04 1238.92L1044.99 1234.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1097.99 1234.31L1044.99 1234.92L1045.04 1238.92L1098.03 1238.31L1097.99 1234.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1150.99 1233.69L1097.99 1234.3L1098.04 1238.3L1151.03 1237.69L1150.99 1233.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1203.98 1233.08L1150.98 1233.69L1151.03 1237.69L1204.02 1237.08L1203.98 1233.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1256.98 1232.46L1203.98 1233.07L1204.03 1237.07L1257.02 1236.46L1256.98 1232.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1309.98 1231.85L1256.98 1232.46L1257.03 1236.46L1310.02 1235.85L1309.98 1231.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1362.97 1231.23L1309.97 1231.84L1310.02 1235.84L1363.01 1235.23L1362.97 1231.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1415.97 1230.62L1362.97 1231.23L1363.02 1235.23L1416.01 1234.62L1415.97 1230.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1468.96 1230L1415.96 1230.61L1416.01 1234.61L1469 1234L1468.96 1230Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M939.042 1240.15L886.046 1240.76L886.092 1244.76L939.089 1244.15L939.042 1240.15Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M992.039 1239.54L939.043 1240.15L939.089 1244.15L992.086 1243.54L992.039 1239.54Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1045.04 1238.92L992.039 1239.53L992.085 1243.53L1045.08 1242.92L1045.04 1238.92Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1098.04 1238.31L1045.04 1238.92L1045.09 1242.92L1098.08 1242.31L1098.04 1238.31Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1151.03 1237.69L1098.03 1238.3L1098.08 1242.3L1151.07 1241.69L1151.03 1237.69Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1204.03 1237.08L1151.03 1237.69L1151.08 1241.69L1204.07 1241.08L1204.03 1237.08Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1257.03 1236.46L1204.03 1237.07L1204.08 1241.07L1257.07 1240.46L1257.03 1236.46Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1310.02 1235.85L1257.02 1236.46L1257.07 1240.46L1310.06 1239.85L1310.02 1235.85Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1363.02 1235.23L1310.02 1235.84L1310.07 1239.84L1363.06 1239.23L1363.02 1235.23Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1416.01 1234.62L1363.01 1235.23L1363.06 1239.23L1416.05 1238.62L1416.01 1234.62Z"
      fill="#FD6EF7"
    />
    <path
      id="elvinsan-1"
      d="M1469.01 1234L1416.01 1234.61L1416.06 1238.61L1469.05 1238L1469.01 1234Z"
      fill="#FD6EF7"
    />
    <path
      d="M1157 1245.5L1473.5 1242V1230L1414 1166V1104.5H1387"
      stroke="#29FD2E"
    />
    <path
      d="M1087.5 1129.5H1082.5V1147.5H1087.5V1129.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1098.5 1129.5H1093.5V1147.5H1098.5V1129.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M914 1145H905V1150H914V1145Z"
      fill="#C4C4C4"
    />
    <path
      d="M927 1145H918V1150H927V1145Z"
      fill="#C4C4C4"
    />
    <path
      d="M1274 1142H1267V1146H1274V1142Z"
      fill="#C4C4C4"
    />
    <path
      d="M1284 1141H1277V1145H1284V1141Z"
      fill="#C4C4C4"
    />
    <path
      d="M1097 1202H1093V1206H1097V1202Z"
      fill="#0A0000"
    />
    <path
      d="M1096 1190.5L1145.5 1189.5"
      stroke="#F06730"
    />
    <path
      d="M1140 1188H1131V1192H1140V1188Z"
      fill="#C4C4C4"
    />
    <path
      d="M1154 1188H1145V1192H1154V1188Z"
      fill="#C4C4C4"
    />
    <path
      d="M1097 1189H1093V1192H1097V1189Z"
      fill="#0A0000"
    />
    <path
      d="M967 1203H957V1208H967V1203Z"
      fill="#C4C4C4"
    />
    <path
      d="M980 1203H970V1208H980V1203Z"
      fill="#C4C4C4"
    />
    <path
      d="M1330 1200H1322V1204H1330V1200Z"
      fill="#C4C4C4"
    />
    <path
      d="M1345 1200H1337V1204H1345V1200Z"
      fill="#C4C4C4"
    />
    <path
      d="M1187 1384L1346 1383"
      stroke="#F06730"
    />
    <path
      d="M1474 1243.5V1320H1499.5V1425.5H1085.5"
      stroke="#29FD2E"
    />
    <path
      d="M1203 1274H1187.5V1386"
      stroke="#333CF6"
    />
    <path
      id="seçilsan-1"
      d="M971 1254H918V1258H971V1254Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1024 1254H971V1258H1024V1254Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1077 1254H1024V1258H1077V1254Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1130 1254H1077V1258H1130V1254Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M971 1258H918V1262H971V1258Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1024 1258H971V1262H1024V1258Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1077 1258H1024V1262H1077V1258Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1130 1258H1077V1262H1130V1258Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1310 1253H1257V1257H1310V1253Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1363 1253H1310V1257H1363V1253Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1416 1253H1363V1257H1416V1253Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1469 1253H1416V1257H1469V1253Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1310 1257H1257V1261H1310V1257Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1363 1257H1310V1261H1363V1257Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1416 1257H1363V1261H1416V1257Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1469 1257H1416V1261H1469V1257Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1310 1269H1257V1273H1310V1269Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1363 1269H1310V1273H1363V1269Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1416 1269H1363V1273H1416V1269Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1469 1269H1416V1273H1469V1269Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1310 1273H1257V1277H1310V1273Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1363 1273H1310V1277H1363V1273Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1416 1273H1363V1277H1416V1273Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1469 1273H1416V1277H1469V1273Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M991 1267H938V1271H991V1267Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1044 1267H991V1271H1044V1267Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1097 1267H1044V1271H1097V1267Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1150 1267H1097V1271H1150V1267Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M991 1271H938V1275H991V1271Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1044 1271H991V1275H1044V1271Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1097 1271H1044V1275H1097V1271Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1150 1271H1097V1275H1150V1271Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1079 1355H1026V1359H1079V1355Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1132 1355H1079V1359H1132V1355Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1185 1355H1132V1359H1185V1355Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1238 1355H1185V1359H1238V1355Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1291 1355H1238V1359H1291V1355Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1344 1355H1291V1359H1344V1355Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1397 1355H1344V1359H1397V1355Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1450 1355H1397V1359H1450V1355Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1079 1359H1026V1363H1079V1359Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1132 1359H1079V1363H1132V1359Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1185 1359H1132V1363H1185V1359Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1238 1359H1185V1363H1238V1359Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1291 1359H1238V1363H1291V1359Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1344 1359H1291V1363H1344V1359Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1397 1359H1344V1363H1397V1359Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1450 1359H1397V1363H1450V1359Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1033 1287H980V1291H1033V1287Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1086 1287H1033V1291H1086V1287Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1139 1287H1086V1291H1139V1287Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1192 1287H1139V1291H1192V1287Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1245 1287H1192V1291H1245V1287Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1298 1287H1245V1291H1298V1287Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1351 1287H1298V1291H1351V1287Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1404 1287H1351V1291H1404V1287Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1457 1287H1404V1291H1457V1287Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1033 1283H980V1287H1033V1283Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1086 1283H1033V1287H1086V1283Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1139 1283H1086V1287H1139V1283Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1192 1283H1139V1287H1192V1283Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1245 1283H1192V1287H1245V1283Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1298 1283H1245V1287H1298V1283Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1351 1283H1298V1287H1351V1283Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1404 1283H1351V1287H1404V1283Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1457 1283H1404V1287H1457V1283Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1033 1301H980V1305H1033V1301Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1086 1301H1033V1305H1086V1301Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1139 1301H1086V1305H1139V1301Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1192 1301H1139V1305H1192V1301Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1245 1301H1192V1305H1245V1301Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1298 1301H1245V1305H1298V1301Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1351 1301H1298V1305H1351V1301Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1404 1301H1351V1305H1404V1301Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1457 1301H1404V1305H1457V1301Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1033 1297H980V1301H1033V1297Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1086 1297H1033V1301H1086V1297Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1139 1297H1086V1301H1139V1297Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1192 1297H1139V1301H1192V1297Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1245 1297H1192V1301H1245V1297Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1298 1297H1245V1301H1298V1297Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1351 1297H1298V1301H1351V1297Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1404 1297H1351V1301H1404V1297Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1457 1297H1404V1301H1457V1297Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1033 1315H980V1319H1033V1315Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1086 1315H1033V1319H1086V1315Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1139 1315H1086V1319H1139V1315Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1192 1315H1139V1319H1192V1315Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1245 1315H1192V1319H1245V1315Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1298 1315H1245V1319H1298V1315Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1351 1315H1298V1319H1351V1315Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1404 1315H1351V1319H1404V1315Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1457 1315H1404V1319H1457V1315Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1033 1311H980V1315H1033V1311Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1086 1311H1033V1315H1086V1311Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1139 1311H1086V1315H1139V1311Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1192 1311H1139V1315H1192V1311Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1245 1311H1192V1315H1245V1311Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1298 1311H1245V1315H1298V1311Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1351 1311H1298V1315H1351V1311Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1404 1311H1351V1315H1404V1311Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1457 1311H1404V1315H1457V1311Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1067 1330H1014V1334H1067V1330Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1120 1330H1067V1334H1120V1330Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1173 1330H1120V1334H1173V1330Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1226 1330H1173V1334H1226V1330Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1279 1330H1226V1334H1279V1330Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1332 1330H1279V1334H1332V1330Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1385 1330H1332V1334H1385V1330Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1438 1330H1385V1334H1438V1330Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1491 1330H1438V1334H1491V1330Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1067 1326H1014V1330H1067V1326Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1120 1326H1067V1330H1120V1326Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1173 1326H1120V1330H1173V1326Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1226 1326H1173V1330H1226V1326Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1279 1326H1226V1330H1279V1326Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1332 1326H1279V1330H1332V1326Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1385 1326H1332V1330H1385V1326Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1438 1326H1385V1330H1438V1326Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1491 1326H1438V1330H1491V1326Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1070 1344H1017V1348H1070V1344Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1123 1344H1070V1348H1123V1344Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1176 1344H1123V1348H1176V1344Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1229 1344H1176V1348H1229V1344Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1282 1344H1229V1348H1282V1344Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1335 1344H1282V1348H1335V1344Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1388 1344H1335V1348H1388V1344Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1441 1344H1388V1348H1441V1344Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1494 1344H1441V1348H1494V1344Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1070 1340H1017V1344H1070V1340Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1123 1340H1070V1344H1123V1340Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1176 1340H1123V1344H1176V1340Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1229 1340H1176V1344H1229V1340Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1282 1340H1229V1344H1282V1340Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1335 1340H1282V1344H1335V1340Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1388 1340H1335V1344H1388V1340Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1441 1340H1388V1344H1441V1340Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1494 1340H1441V1344H1494V1340Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1112 1370H1059V1374H1112V1370Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1165 1370H1112V1374H1165V1370Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1218 1370H1165V1374H1218V1370Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1271 1370H1218V1374H1271V1370Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1324 1370H1271V1374H1324V1370Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1377 1370H1324V1374H1377V1370Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1430 1370H1377V1374H1430V1370Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1112 1374H1059V1378H1112V1374Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1165 1374H1112V1378H1165V1374Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1218 1374H1165V1378H1218V1374Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1271 1374H1218V1378H1271V1374Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1324 1374H1271V1378H1324V1374Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1377 1374H1324V1378H1377V1374Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1430 1374H1377V1378H1430V1374Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1123 1385H1070V1389H1123V1385Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1176 1385H1123V1389H1176V1385Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1229 1385H1176V1389H1229V1385Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1282 1385H1229V1389H1282V1385Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1335 1385H1282V1389H1335V1385Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1388 1385H1335V1389H1388V1385Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1441 1385H1388V1389H1441V1385Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1123 1389H1070V1393H1123V1389Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1176 1389H1123V1393H1176V1389Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1229 1389H1176V1393H1229V1389Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1282 1389H1229V1393H1282V1389Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1335 1389H1282V1393H1335V1389Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1388 1389H1335V1393H1388V1389Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1441 1389H1388V1393H1441V1389Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1131 1400H1078V1404H1131V1400Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1184 1400H1131V1404H1184V1400Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1237 1400H1184V1404H1237V1400Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1290 1400H1237V1404H1290V1400Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1343 1400H1290V1404H1343V1400Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1396 1400H1343V1404H1396V1400Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1449 1400H1396V1404H1449V1400Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1131 1404H1078V1408H1131V1404Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1184 1404H1131V1408H1184V1404Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1237 1404H1184V1408H1237V1404Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1290 1404H1237V1408H1290V1404Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1343 1404H1290V1408H1343V1404Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1396 1404H1343V1408H1396V1404Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1449 1404H1396V1408H1449V1404Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1138 1414H1085V1418H1138V1414Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1191 1414H1138V1418H1191V1414Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1244 1414H1191V1418H1244V1414Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1297 1414H1244V1418H1297V1414Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1350 1414H1297V1418H1350V1414Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1403 1414H1350V1418H1403V1414Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1456 1414H1403V1418H1456V1414Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1138 1418H1085V1422H1138V1418Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1191 1418H1138V1422H1191V1418Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1244 1418H1191V1422H1244V1418Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1297 1418H1244V1422H1297V1418Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1350 1418H1297V1422H1350V1418Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1403 1418H1350V1422H1403V1418Z"
      fill="#FDCB6E"
    />
    <path
      id="seçilsan-1"
      d="M1456 1418H1403V1422H1456V1418Z"
      fill="#FDCB6E"
    />
    <path
      d="M1197.5 1271.5H1180.5V1276.5H1197.5V1271.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1218.5 1271.5H1201.5V1276.5H1218.5V1271.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1188.5 1309H1258"
      stroke="#F06730"
    />
    <path
      d="M1251 1307H1244V1311H1251V1307Z"
      fill="#C4C4C4"
    />
    <path
      d="M1262 1307H1255V1311H1262V1307Z"
      fill="#C4C4C4"
    />
    <path
      d="M1192 1306H1184V1311H1192V1306Z"
      fill="black"
    />
    <path
      d="M1192 1292H1184V1297H1192V1292Z"
      fill="black"
    />
    <path
      d="M1339 1380H1329V1385H1339V1380Z"
      fill="#C4C4C4"
    />
    <path
      d="M1354 1380H1344V1385H1354V1380Z"
      fill="#C4C4C4"
    />
    <path
      d="M1190 1382H1187V1385H1190V1382Z"
      fill="black"
    />
    <path
      d="M1188 1368H1145"
      stroke="#F06730"
    />
    <path
      d="M1145 1366H1137V1370H1145V1366Z"
      fill="#C4C4C4"
    />
    <path
      d="M1156 1366H1148V1370H1156V1366Z"
      fill="#C4C4C4"
    />
    <path
      d="M1190 1367H1187V1370H1190V1367Z"
      fill="black"
    />
    <path
      d="M1240 1577H1235V1581H1240V1577Z"
      fill="#0A0000"
    />
    <path
      d="M1240 1636H1235V1640H1240V1636Z"
      fill="#0A0000"
    />
    <path
      d="M1252 1455.5H1237V1641"
      stroke="#333CF6"
    />
    <path
      d="M1238 1638H1190"
      stroke="#F06730"
    />
    <path
      d="M1139.5 1506.5H1236.5"
      stroke="#F06730"
    />
    <path
      d="M1237 1521.5H1270.5"
      stroke="#F06730"
    />
    <path
      d="M1236.5 1478H1394.5"
      stroke="#F06730"
    />
    <path
      id="abaysan-1"
      d="M1177 1669H1124V1673H1177V1669Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1230 1669H1177V1673H1230V1669Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1177 1673H1124V1677H1177V1673Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1230 1673H1177V1677H1230V1673Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1177 1684H1124V1688H1177V1684Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1230 1684H1177V1688H1230V1684Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1177 1688H1124V1692H1177V1688Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1230 1688H1177V1692H1230V1688Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1145 1437H1092V1441H1145V1437Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1198 1437H1145V1441H1198V1437Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1145 1441H1092V1445H1145V1441Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1198 1441H1145V1445H1198V1441Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1146 1450H1093V1454H1146V1450Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1199 1450H1146V1454H1199V1450Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1146 1454H1093V1458H1146V1454Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1199 1454H1146V1458H1199V1454Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1357 1437H1304V1441H1357V1437Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1410 1437H1357V1441H1410V1437Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1463 1437H1410V1441H1463V1437Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1357 1441H1304V1445H1357V1441Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1410 1441H1357V1445H1410V1441Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1463 1441H1410V1445H1463V1441Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1176 1656H1123V1660H1176V1656Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1229 1656H1176V1660H1229V1656Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1282 1656H1229V1660H1282V1656Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1176 1660H1123V1664H1176V1660Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1229 1660H1176V1664H1229V1660Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1282 1660H1229V1664H1282V1660Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1359 1452H1306V1456H1359V1452Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1412 1452H1359V1456H1412V1452Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1465 1452H1412V1456H1465V1452Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1359 1456H1306V1460H1359V1456Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1412 1456H1359V1460H1412V1456Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1465 1456H1412V1460H1465V1456Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1150 1466H1097V1470H1150V1466Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1203 1466H1150V1470H1203V1466Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1256 1466H1203V1470H1256V1466Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1309 1466H1256V1470H1309V1466Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1362 1466H1309V1470H1362V1466Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1415 1466H1362V1470H1415V1466Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1468 1466H1415V1470H1468V1466Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1150 1470H1097V1474H1150V1470Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1203 1470H1150V1474H1203V1470Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1256 1470H1203V1474H1256V1470Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1309 1470H1256V1474H1309V1470Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1362 1470H1309V1474H1362V1470Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1415 1470H1362V1474H1415V1470Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1468 1470H1415V1474H1468V1470Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1480H1102V1484H1155V1480Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1480H1155V1484H1208V1480Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1480H1208V1484H1261V1480Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1480H1261V1484H1314V1480Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1480H1314V1484H1367V1480Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1480H1367V1484H1420V1480Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1480H1420V1484H1473V1480Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1484H1102V1488H1155V1484Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1484H1155V1488H1208V1484Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1484H1208V1488H1261V1484Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1484H1261V1488H1314V1484Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1484H1314V1488H1367V1484Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1484H1367V1488H1420V1484Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1484H1420V1488H1473V1484Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1495H1102V1499H1155V1495Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1495H1155V1499H1208V1495Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1495H1208V1499H1261V1495Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1495H1261V1499H1314V1495Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1495H1314V1499H1367V1495Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1495H1367V1499H1420V1495Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1495H1420V1499H1473V1495Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1499H1102V1503H1155V1499Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1499H1155V1503H1208V1499Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1499H1208V1503H1261V1499Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1499H1261V1503H1314V1499Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1499H1314V1503H1367V1499Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1499H1367V1503H1420V1499Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1499H1420V1503H1473V1499Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1523H1102V1527H1155V1523Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1523H1155V1527H1208V1523Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1523H1208V1527H1261V1523Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1523H1261V1527H1314V1523Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1523H1314V1527H1367V1523Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1523H1367V1527H1420V1523Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1523H1420V1527H1473V1523Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1527H1102V1531H1155V1527Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1527H1155V1531H1208V1527Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1527H1208V1531H1261V1527Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1527H1261V1531H1314V1527Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1527H1314V1531H1367V1527Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1527H1367V1531H1420V1527Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1527H1420V1531H1473V1527Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1538H1102V1542H1155V1538Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1538H1155V1542H1208V1538Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1538H1208V1542H1261V1538Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1538H1261V1542H1314V1538Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1538H1314V1542H1367V1538Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1538H1367V1542H1420V1538Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1538H1420V1542H1473V1538Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1542H1102V1546H1155V1542Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1542H1155V1546H1208V1542Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1542H1208V1546H1261V1542Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1542H1261V1546H1314V1542Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1542H1314V1546H1367V1542Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1542H1367V1546H1420V1542Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1542H1420V1546H1473V1542Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1553H1102V1557H1155V1553Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1553H1155V1557H1208V1553Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1553H1208V1557H1261V1553Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1553H1261V1557H1314V1553Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1553H1314V1557H1367V1553Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1553H1367V1557H1420V1553Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1553H1420V1557H1473V1553Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1557H1102V1561H1155V1557Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1557H1155V1561H1208V1557Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1557H1208V1561H1261V1557Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1557H1261V1561H1314V1557Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1557H1314V1561H1367V1557Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1557H1367V1561H1420V1557Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1557H1420V1561H1473V1557Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1170 1625H1117V1629H1170V1625Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1223 1625H1170V1629H1223V1625Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1276 1625H1223V1629H1276V1625Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1329 1625H1276V1629H1329V1625Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1170 1629H1117V1633H1170V1629Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1223 1629H1170V1633H1223V1629Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1276 1629H1223V1633H1276V1629Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1329 1629H1276V1633H1329V1629Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1164 1611H1111V1615H1164V1611Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1217 1611H1164V1615H1217V1611Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1270 1611H1217V1615H1270V1611Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1323 1611H1270V1615H1323V1611Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1376 1611H1323V1615H1376V1611Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1164 1615H1111V1619H1164V1615Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1217 1615H1164V1619H1217V1615Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1270 1615H1217V1619H1270V1615Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1323 1615H1270V1619H1323V1615Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1376 1615H1323V1619H1376V1615Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1568H1102V1572H1155V1568Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1568H1155V1572H1208V1568Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1568H1208V1572H1261V1568Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1568H1261V1572H1314V1568Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1568H1314V1572H1367V1568Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1568H1367V1572H1420V1568Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1568H1420V1572H1473V1568Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1572H1102V1576H1155V1572Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1572H1155V1576H1208V1572Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1572H1208V1576H1261V1572Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1572H1261V1576H1314V1572Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1572H1314V1576H1367V1572Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1572H1367V1576H1420V1572Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1572H1420V1576H1473V1572Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1164 1581H1111V1585H1164V1581Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1217 1581H1164V1585H1217V1581Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1270 1581H1217V1585H1270V1581Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1323 1581H1270V1585H1323V1581Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1376 1581H1323V1585H1376V1581Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1429 1581H1376V1585H1429V1581Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1164 1585H1111V1589H1164V1585Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1217 1585H1164V1589H1217V1585Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1270 1585H1217V1589H1270V1585Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1323 1585H1270V1589H1323V1585Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1376 1585H1323V1589H1376V1585Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1429 1585H1376V1589H1429V1585Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1159 1597H1106V1601H1159V1597Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1212 1597H1159V1601H1212V1597Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1265 1597H1212V1601H1265V1597Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1318 1597H1265V1601H1318V1597Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1371 1597H1318V1601H1371V1597Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1424 1597H1371V1601H1424V1597Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1159 1601H1106V1605H1159V1601Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1212 1601H1159V1605H1212V1601Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1265 1601H1212V1605H1265V1601Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1318 1601H1265V1605H1318V1601Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1371 1601H1318V1605H1371V1601Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1424 1601H1371V1605H1424V1601Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1510H1102V1514H1155V1510Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1510H1155V1514H1208V1510Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1510H1208V1514H1261V1510Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1510H1261V1514H1314V1510Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1510H1314V1514H1367V1510Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1510H1367V1514H1420V1510Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1510H1420V1514H1473V1510Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1155 1514H1102V1518H1155V1514Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1208 1514H1155V1518H1208V1514Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1261 1514H1208V1518H1261V1514Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1314 1514H1261V1518H1314V1514Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1367 1514H1314V1518H1367V1514Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1420 1514H1367V1518H1420V1514Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1473 1514H1420V1518H1473V1514Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1175 1640H1122V1644H1175V1640Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1228 1640H1175V1644H1228V1640Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1281 1640H1228V1644H1281V1640Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1175 1644H1122V1648H1175V1644Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1228 1644H1175V1648H1228V1644Z"
      fill="#7AAFDF"
    />
    <path
      id="abaysan-1"
      d="M1281 1644H1228V1648H1281V1644Z"
      fill="#7AAFDF"
    />
    <path
      d="M1246.5 1453.5H1229.5V1459.5H1246.5V1453.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1268.5 1453.5H1251.5V1459.5H1268.5V1453.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1390 1475H1382V1480H1390V1475Z"
      fill="#C4C4C4"
    />
    <path
      d="M1402 1475H1394V1480H1402V1475Z"
      fill="#C4C4C4"
    />
    <path
      d="M1239 1476H1235V1479H1239V1476Z"
      fill="#0A0000"
    />
    <path
      d="M1265 1519H1256V1523H1265V1519Z"
      fill="#C4C4C4"
    />
    <path
      d="M1279 1519H1270V1523H1279V1519Z"
      fill="#C4C4C4"
    />
    <path
      d="M1140 1505H1130V1510H1140V1505Z"
      fill="#C4C4C4"
    />
    <path
      d="M1154 1505H1144V1510H1154V1505Z"
      fill="#C4C4C4"
    />
    <path
      d="M1239 1505H1235V1508H1239V1505Z"
      fill="#0A0000"
    />
    <path
      d="M1239 1519H1235V1522H1239V1519Z"
      fill="#0A0000"
    />
    <path
      d="M1237 1578.5H1366"
      stroke="#F06730"
    />
    <path
      d="M1360 1577H1349V1581H1360V1577Z"
      fill="#C4C4C4"
    />
    <path
      d="M1377 1577H1366V1581H1377V1577Z"
      fill="#C4C4C4"
    />
    <path
      d="M1191 1636H1183V1640H1191V1636Z"
      fill="#C4C4C4"
    />
    <path
      d="M1205 1636H1197V1640H1205V1636Z"
      fill="#C4C4C4"
    />
    <path
      d="M1688.5 986.5H1670.5V992.5H1688.5V986.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1656.5 978.5H1670.5"
      stroke="#333CF6"
    />
    <path
      d="M1656 989H1670"
      stroke="#333CF6"
    />
    <path
      d="M1665.5 975.5H1647.5V981.5H1665.5V975.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1688.5 975.5H1670.5V981.5H1688.5V975.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1665.5 986.5H1647.5V992.5H1665.5V986.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1610 889H1656.5"
      stroke="#F06730"
    />
    <path
      d="M1659 887H1655V891H1659V887Z"
      fill="#0A0000"
    />
    <path
      d="M1901 722H1897V726H1901V722Z"
      fill="black"
    />
    <path
      d="M1908 739H1904V743H1908V739Z"
      fill="black"
    />
    <path
      id="kahrasan-2"
      d="M1602 476H1549V480H1602V476Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1602 480H1549V484H1602V480Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1602 493H1549V497H1602V493Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1655 493H1602V497H1655V493Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1708 493H1655V497H1708V493Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1602 497H1549V501H1602V497Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1655 497H1602V501H1655V497Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1708 497H1655V501H1708V497Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1576 686H1523V690H1576V686Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1629 686H1576V690H1629V686Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1682 686H1629V690H1682V686Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1735 686H1682V690H1735V686Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1788 686H1735V690H1788V686Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1841 686H1788V690H1841V686Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1576 690H1523V694H1576V690Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1629 690H1576V694H1629V690Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1682 690H1629V694H1682V690Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1735 690H1682V694H1735V690Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1788 690H1735V694H1788V690Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1841 690H1788V694H1841V690Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1589 704H1536V708H1589V704Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1642 704H1589V708H1642V704Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1695 704H1642V708H1695V704Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1748 704H1695V708H1748V704Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1801 704H1748V708H1801V704Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1854 704H1801V708H1854V704Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1589 708H1536V712H1589V708Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1642 708H1589V712H1642V708Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1695 708H1642V712H1695V708Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1748 708H1695V712H1748V708Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1801 708H1748V712H1801V708Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1854 708H1801V712H1854V708Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1594 721H1541V725H1594V721Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1647 721H1594V725H1647V721Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1700 721H1647V725H1700V721Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1753 721H1700V725H1753V721Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1806 721H1753V725H1806V721Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1859 721H1806V725H1859V721Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1594 725H1541V729H1594V725Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1647 725H1594V729H1647V725Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1700 725H1647V729H1700V725Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1753 725H1700V729H1753V725Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1806 725H1753V729H1806V725Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1859 725H1806V729H1859V725Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1599 738H1546V742H1599V738Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1652 738H1599V742H1652V738Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1705 738H1652V742H1705V738Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1758 738H1705V742H1758V738Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1811 738H1758V742H1811V738Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1864 738H1811V742H1864V738Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1599 742H1546V746H1599V742Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1652 742H1599V746H1652V742Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1705 742H1652V746H1705V742Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1758 742H1705V746H1758V742Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1811 742H1758V746H1811V742Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1864 742H1811V746H1864V742Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1610 756H1557V760H1610V756Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1663 756H1610V760H1663V756Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1716 756H1663V760H1716V756Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1769 756H1716V760H1769V756Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1822 756H1769V760H1822V756Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1875 756H1822V760H1875V756Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1610 760H1557V764H1610V760Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1663 760H1610V764H1663V760Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1716 760H1663V764H1716V760Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1769 760H1716V764H1769V760Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1822 760H1769V764H1822V760Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1875 760H1822V764H1875V760Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1642 774H1589V778H1642V774Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1695 774H1642V778H1695V774Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1748 774H1695V778H1748V774Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1801 774H1748V778H1801V774Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1854 774H1801V778H1854V774Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1907 774H1854V778H1907V774Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1642 778H1589V782H1642V778Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1695 778H1642V782H1695V778Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1748 778H1695V782H1748V778Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1801 778H1748V782H1801V778Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1854 778H1801V782H1854V778Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1907 778H1854V782H1907V778Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1570 785.5H1919L1874.5 703.5V641L1892 620V489.5"
      stroke="#29FD2E"
    />
    <path
      id="kahrasan-2"
      d="M1601 511H1548V515H1601V511Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 511H1601V515H1654V511Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 511H1654V515H1707V511Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 511H1707V515H1760V511Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 511H1760V515H1813V511Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 511H1813V515H1866V511Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1601 515H1548V519H1601V515Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 515H1601V519H1654V515Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 515H1654V519H1707V515Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 515H1707V519H1760V515Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 515H1760V519H1813V515Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 515H1813V519H1866V515Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1601 529H1548V533H1601V529Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 529H1601V533H1654V529Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 529H1654V533H1707V529Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 529H1707V533H1760V529Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 529H1760V533H1813V529Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 529H1813V533H1866V529Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1601 533H1548V537H1601V533Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 533H1601V537H1654V533Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 533H1654V537H1707V533Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 533H1707V537H1760V533Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 533H1760V537H1813V533Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 533H1813V537H1866V533Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1601 547H1548V551H1601V547Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 547H1601V551H1654V547Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 547H1654V551H1707V547Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 547H1707V551H1760V547Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 547H1760V551H1813V547Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 547H1813V551H1866V547Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1601 551H1548V555H1601V551Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 551H1601V555H1654V551Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 551H1654V555H1707V551Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 551H1707V555H1760V551Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 551H1760V555H1813V551Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 551H1813V555H1866V551Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1601 563H1548V567H1601V563Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 563H1601V567H1654V563Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 563H1654V567H1707V563Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 563H1707V567H1760V563Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 563H1760V567H1813V563Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 563H1813V567H1866V563Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1601 567H1548V571H1601V567Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 567H1601V571H1654V567Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 567H1654V571H1707V567Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 567H1707V571H1760V567Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 567H1760V571H1813V567Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 567H1813V571H1866V567Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1601 581H1548V585H1601V581Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 581H1601V585H1654V581Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 581H1654V585H1707V581Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 581H1707V585H1760V581Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 581H1760V585H1813V581Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 581H1813V585H1866V581Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1601 585H1548V589H1601V585Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 585H1601V589H1654V585Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 585H1654V589H1707V585Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 585H1707V589H1760V585Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 585H1760V589H1813V585Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 585H1813V589H1866V585Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1601 599H1548V603H1601V599Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 599H1601V603H1654V599Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 599H1654V603H1707V599Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 599H1707V603H1760V599Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 599H1760V603H1813V599Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 599H1813V603H1866V599Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1601 603H1548V607H1601V603Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 603H1601V607H1654V603Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 603H1654V607H1707V603Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 603H1707V607H1760V603Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 603H1760V607H1813V603Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 603H1813V607H1866V603Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1601 617H1548V621H1601V617Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 617H1601V621H1654V617Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 617H1654V621H1707V617Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 617H1707V621H1760V617Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 617H1760V621H1813V617Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 617H1813V621H1866V617Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1601 621H1548V625H1601V621Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1654 621H1601V625H1654V621Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1707 621H1654V625H1707V621Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1760 621H1707V625H1760V621Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1813 621H1760V625H1813V621Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1866 621H1813V625H1866V621Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1602 634H1549V638H1602V634Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1655 634H1602V638H1655V634Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1708 634H1655V638H1708V634Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1761 634H1708V638H1761V634Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1814 634H1761V638H1814V634Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1602 638H1549V642H1602V638Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1655 638H1602V642H1655V638Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1708 638H1655V642H1708V638Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1761 638H1708V642H1761V638Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1814 638H1761V642H1814V638Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1602 651H1549V655H1602V651Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1655 651H1602V655H1655V651Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1708 651H1655V655H1708V651Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1761 651H1708V655H1761V651Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1814 651H1761V655H1814V651Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1602 655H1549V659H1602V655Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1655 655H1602V659H1655V655Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1708 655H1655V659H1708V655Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1761 655H1708V659H1761V655Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1814 655H1761V659H1814V655Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1576 669H1523V673H1576V669Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1629 669H1576V673H1629V669Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1682 669H1629V673H1682V669Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1735 669H1682V673H1735V669Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1788 669H1735V673H1788V669Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1576 673H1523V677H1576V673Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1629 673H1576V677H1629V673Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1682 673H1629V677H1682V673Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1735 673H1682V677H1735V673Z"
      fill="#415084"
    />
    <path
      id="kahrasan-2"
      d="M1788 673H1735V677H1788V673Z"
      fill="#415084"
    />
    <path
      d="M1663 733H1650V738H1663V733Z"
      fill="#C4C4C4"
    />
    <path
      d="M1682 733H1669V738H1682V733Z"
      fill="#C4C4C4"
    />
    <path
      d="M1663 736H1861.5"
      stroke="#F06730"
    />
    <path
      d="M1844 651.269L1813.5 648M1861.5 736V719.5L1850 698.5L1844 670.5V667L1861.5 736ZM1844 667H1852.5H1844ZM1844 667V651.269V667ZM1844 651.269L1869 624V546.5L1844 651.269Z"
      stroke="#333CF6"
    />
    <path
      d="M1864 734H1859V738H1864V734Z"
      fill="black"
    />
    <path
      d="M1864 718H1859V722H1864V718Z"
      fill="black"
    />
    <path
      d="M1853 696H1848V700H1853V696Z"
      fill="black"
    />
    <path
      d="M1846.5 653.5H1840.5V670.5H1846.5V653.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1857.5 653.5H1851.5V670.5H1857.5V653.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1871 622H1867V626H1871V622Z"
      fill="black"
    />
    <path
      d="M1871 558H1867V562H1871V558Z"
      fill="black"
    />
    <path
      d="M1868.5 561H1784"
      stroke="#F06730"
    />
    <path
      d="M1871 543H1867V547H1871V543Z"
      fill="black"
    />
    <path
      d="M1868.5 545L1641.5 544.5"
      stroke="#F06730"
    />
    <path
      d="M1815 647H1811V651H1815V647Z"
      fill="black"
    />
    <path
      d="M1811 649L1627 648.5"
      stroke="#F06730"
    />
    <path
      d="M1627 646H1618V651H1627V646Z"
      fill="#C4C4C4"
    />
    <path
      d="M1640 646H1631V651H1640V646Z"
      fill="#C4C4C4"
    />
    <path
      d="M1786 559H1775V563H1786V559Z"
      fill="#C4C4C4"
    />
    <path
      d="M1800 559H1789V563H1800V559Z"
      fill="#C4C4C4"
    />
    <path
      d="M1642 542H1630V547H1642V542Z"
      fill="#C4C4C4"
    />
    <path
      d="M1659 542H1647V547H1659V542Z"
      fill="#C4C4C4"
    />
    <path
      d="M2046 737H2030V743H2046V737Z"
      fill="#C4C4C4"
    />
    <path
      d="M1905 622H2142.5"
      stroke="#F06730"
    />
    <path
      d="M1901 724L2183 723"
      stroke="#F06730"
    />
    <path
      d="M1924.5 639H2006.5"
      stroke="#F06730"
    />
    <path
      d="M1907.5 741.5L2029.5 740.5"
      stroke="#F06730"
    />
    <path
      d="M1904 653.5L1922 642.5M1903 537L1904 653.5L1903 537ZM1904 653.5L1897 661.5V678L1893 710L1899 723.5L1905.5 740L1904 653.5Z"
      stroke="#333CF6"
    />
    <path
      id="çaglasan-2"
      d="M1965 505H1912V509H1965V505Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2018 505H1965V509H2018V505Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2071 505H2018V509H2071V505Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1965 509H1912V513H1965V509Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2018 509H1965V513H2018V509Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2071 509H2018V513H2071V509Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1950 522H1897V526H1950V522Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2003 522H1950V526H2003V522Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2056 522H2003V526H2056V522Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2109 522H2056V526H2109V522Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1950 526H1897V530H1950V526Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2003 526H1950V530H2003V526Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2056 526H2003V530H2056V526Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2109 526H2056V530H2109V526Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 539H1895V543H1948V539Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 539H1948V543H2001V539Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 539H2001V543H2054V539Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 539H2054V543H2107V539Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 539H2107V543H2160V539Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 543H1895V547H1948V543Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 543H1948V547H2001V543Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 543H2001V547H2054V543Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 543H2054V547H2107V543Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 543H2107V547H2160V543Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 556H1895V560H1948V556Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 556H1948V560H2001V556Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 556H2001V560H2054V556Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 556H2054V560H2107V556Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 556H2107V560H2160V556Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 560H1895V564H1948V560Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 560H1948V564H2001V560Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 560H2001V564H2054V560Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 560H2054V564H2107V560Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 560H2107V564H2160V560Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 573H1895V577H1948V573Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 573H1948V577H2001V573Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 573H2001V577H2054V573Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 573H2054V577H2107V573Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 573H2107V577H2160V573Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 577H1895V581H1948V577Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 577H1948V581H2001V577Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 577H2001V581H2054V577Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 577H2054V581H2107V577Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 577H2107V581H2160V577Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1944 709H1891V713H1944V709Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1997 709H1944V713H1997V709Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2050 709H1997V713H2050V709Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2103 709H2050V713H2103V709Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2156 709H2103V713H2156V709Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2209 709H2156V713H2209V709Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2262 709H2209V713H2262V709Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1944 713H1891V717H1944V713Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1997 713H1944V717H1997V713Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2050 713H1997V717H2050V713Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2103 713H2050V717H2103V713Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2156 713H2103V717H2156V713Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2209 713H2156V717H2209V713Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2262 713H2209V717H2262V713Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 726H1895V730H1948V726Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 726H1948V730H2001V726Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 726H2001V730H2054V726Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 726H2054V730H2107V726Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 726H2107V730H2160V726Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2213 726H2160V730H2213V726Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2266 726H2213V730H2266V726Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 730H1895V734H1948V730Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 730H1948V734H2001V730Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 730H2001V734H2054V730Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 730H2054V734H2107V730Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 730H2107V734H2160V730Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2213 730H2160V734H2213V730Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2266 730H2213V734H2266V730Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1954 743H1901V747H1954V743Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2007 743H1954V747H2007V743Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2060 743H2007V747H2060V743Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2113 743H2060V747H2113V743Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2166 743H2113V747H2166V743Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2219 743H2166V747H2219V743Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2272 743H2219V747H2272V743Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1954 747H1901V751H1954V747Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2007 747H1954V751H2007V747Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2060 747H2007V751H2060V747Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2113 747H2060V751H2113V747Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2166 747H2113V751H2166V747Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2219 747H2166V751H2219V747Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2272 747H2219V751H2272V747Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 590H1895V594H1948V590Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 590H1948V594H2001V590Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 590H2001V594H2054V590Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 590H2054V594H2107V590Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 590H2107V594H2160V590Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2213 590H2160V594H2213V590Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 594H1895V598H1948V594Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 594H1948V598H2001V594Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 594H2001V598H2054V594Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 594H2054V598H2107V594Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 594H2107V598H2160V594Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2213 594H2160V598H2213V594Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 607H1895V611H1948V607Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 607H1948V611H2001V607Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 607H2001V611H2054V607Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 607H2054V611H2107V607Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 607H2107V611H2160V607Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2213 607H2160V611H2213V607Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 611H1895V615H1948V611Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 611H1948V615H2001V611Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 611H2001V615H2054V611Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 611H2054V615H2107V611Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 611H2107V615H2160V611Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2213 611H2160V615H2213V611Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 624H1895V628H1948V624Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 624H1948V628H2001V624Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 624H2001V628H2054V624Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 624H2054V628H2107V624Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 624H2107V628H2160V624Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2213 624H2160V628H2213V624Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1948 628H1895V632H1948V628Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2001 628H1948V632H2001V628Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2054 628H2001V632H2054V628Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2107 628H2054V632H2107V628Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2160 628H2107V632H2160V628Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2213 628H2160V632H2213V628Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1975 658H1922V662H1975V658Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2028 658H1975V662H2028V658Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2081 658H2028V662H2081V658Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2134 658H2081V662H2134V658Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2187 658H2134V662H2187V658Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2240 658H2187V662H2240V658Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1975 662H1922V666H1975V662Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2028 662H1975V666H2028V662Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2081 662H2028V666H2081V662Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2134 662H2081V666H2134V662Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2187 662H2134V666H2187V662Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2240 662H2187V666H2240V662Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1981 675H1928V679H1981V675Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2034 675H1981V679H2034V675Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2087 675H2034V679H2087V675Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2140 675H2087V679H2140V675Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2193 675H2140V679H2193V675Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2246 675H2193V679H2246V675Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1981 679H1928V683H1981V679Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2034 679H1981V683H2034V679Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2087 679H2034V683H2087V679Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2140 679H2087V683H2140V679Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2193 679H2140V683H2193V679Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2246 679H2193V683H2246V679Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1989 692H1936V696H1989V692Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2042 692H1989V696H2042V692Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2095 692H2042V696H2095V692Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2148 692H2095V696H2148V692Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2201 692H2148V696H2201V692Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2254 692H2201V696H2254V692Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1989 696H1936V700H1989V696Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2042 696H1989V700H2042V696Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2095 696H2042V700H2095V696Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2148 696H2095V700H2148V696Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2201 696H2148V700H2201V696Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2254 696H2201V700H2254V696Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2008 760H1955V764H2008V760Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2061 760H2008V764H2061V760Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2114 760H2061V764H2114V760Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2167 760H2114V764H2167V760Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2220 760H2167V764H2220V760Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2273 760H2220V764H2273V760Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2008 764H1955V768H2008V764Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2061 764H2008V768H2061V764Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2114 764H2061V768H2114V764Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2167 764H2114V768H2167V764Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2220 764H2167V768H2220V764Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2273 764H2220V768H2273V764Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2013 777H1960V781H2013V777Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2066 777H2013V781H2066V777Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2119 777H2066V781H2119V777Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2172 777H2119V781H2172V777Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2225 777H2172V781H2225V777Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2278 777H2225V781H2278V777Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2013 781H1960V785H2013V781Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2066 781H2013V785H2066V781Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2119 781H2066V785H2119V781Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2172 781H2119V785H2172V781Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2225 781H2172V785H2225V781Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2278 781H2225V785H2278V781Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1965 641H1912V645H1965V641Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2018 641H1965V645H2018V641Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2071 641H2018V645H2071V641Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2124 641H2071V645H2124V641Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2177 641H2124V645H2177V641Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2230 641H2177V645H2230V641Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M1965 645H1912V649H1965V645Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2018 645H1965V649H2018V645Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2071 645H2018V649H2071V645Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2124 645H2071V649H2124V645Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2177 645H2124V649H2177V645Z"
      fill="#AC8A4B"
    />
    <path
      id="çaglasan-2"
      d="M2230 645H2177V649H2230V645Z"
      fill="#AC8A4B"
    />
    <path
      d="M1890.5 663.5H1884.5V680.5H1890.5V663.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1905 620H1901V624H1905V620Z"
      fill="black"
    />
    <path
      d="M1905 535H1901V539H1905V535Z"
      fill="black"
    />
    <path
      d="M1905 537H2047.5"
      stroke="#F06730"
    />
    <path
      d="M1925 637H1921V641H1925V637Z"
      fill="black"
    />
    <path
      d="M1906 652H1902V656H1906V652Z"
      fill="black"
    />
    <path
      d="M1899 660H1895V664H1899V660Z"
      fill="black"
    />
    <path
      d="M1899.5 663.5H1893.5V680.5H1899.5V663.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M2022 737H2006V743H2022V737Z"
      fill="#C4C4C4"
    />
    <path
      d="M2002 636H1990V641H2002V636Z"
      fill="#C4C4C4"
    />
    <path
      d="M2018 636H2006V641H2018V636Z"
      fill="#C4C4C4"
    />
    <path
      d="M2039 535H2030V539H2039V535Z"
      fill="#C4C4C4"
    />
    <path
      d="M2055 535H2046V539H2055V535Z"
      fill="#C4C4C4"
    />
    <path
      d="M2175 721H2164V726H2175V721Z"
      fill="#C4C4C4"
    />
    <path
      d="M2194 721H2183V726H2194V721Z"
      fill="#C4C4C4"
    />
    <path
      d="M2137 620H2127V624H2137V620Z"
      fill="#C4C4C4"
    />
    <path
      d="M2152 620H2142V624H2152V620Z"
      fill="#C4C4C4"
    />
    <path
      d="M2370 944H2366V948H2370V944Z"
      fill="#0A0000"
    />
    <path
      d="M2381.5 1080.5H2363.5V1086.5H2381.5V1080.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M2403.5 1080.5H2385.5V1086.5H2403.5V1080.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M2461.5 1172H2453.5V1176H2461.5V1172Z"
      fill="#C4C4C4"
    />
    <path
      d="M2476.5 1172H2468.5V1176H2476.5V1172Z"
      fill="#C4C4C4"
    />
    <path
      d="M2565 1102H2553V1106H2565V1102Z"
      fill="#C4C4C4"
    />
    <path
      d="M2585 1102H2573V1106H2585V1102Z"
      fill="#C4C4C4"
    />
    <path
      id="kahrasan-1"
      d="M376 679H323V683H376V679Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M429 679H376V683H429V679Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M482 679H429V683H482V679Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M376 683H323V687H376V683Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M429 683H376V687H429V683Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M482 683H429V687H482V683Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc5"
      d="M597 531H544V535H597V531Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc6"
      d="M650 531H597V535H650V531Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc7"
      d="M703 531H650V535H703V531Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc5"
      d="M597 535H544V539H597V535Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc6"
      d="M650 535H597V539H650V535Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc7"
      d="M703 535H650V539H703V535Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M383 694H330V698H383V694Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M436 694H383V698H436V694Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M383 698H330V702H383V698Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M436 698H383V702H436V698Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc1"
      d="M660 500H607V504H660V500Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc2"
      d="M713 500H660V504H713V500Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc1"
      d="M660 504H607V508H660V504Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc2"
      d="M713 504H660V508H713V504Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc4"
      d="M651 516H598V520H651V516Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc3"
      d="M704 516H651V520H704V516Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc4"
      d="M651 520H598V524H651V520Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc3"
      d="M704 520H651V524H704V520Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc11"
      d="M545 546H492V550H545V546Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc10"
      d="M598 546H545V550H598V546Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc9"
      d="M651 546H598V550H651V546Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc8"
      d="M704 546H651V550H704V546Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc11"
      d="M545 550H492V554H545V550Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc10"
      d="M598 550H545V554H598V550Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc9"
      d="M651 550H598V554H651V550Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc8"
      d="M704 550H651V554H704V550Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M368 664H315V668H368V664Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M421 664H368V668H421V664Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M474 664H421V668H474V664Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M527 664H474V668H527V664Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M580 664H527V668H580V664Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M368 668H315V672H368V668Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M421 668H368V672H421V668Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M474 668H421V672H474V668Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M527 668H474V672H527V668Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M580 668H527V672H580V668Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com5-dc6"
      d="M352 619H299V623H352V619Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com5-dc5"
      d="M405 619H352V623H405V619Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc11"
      d="M458 619H405V623H458V619Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc10"
      d="M511 619H458V623H511V619Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc9"
      d="M564 619H511V623H564V619Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc8"
      d="M617 619H564V623H617V619Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc7"
      d="M670 619H617V623H670V619Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com6-dc6"
      d="M352 623H299V627H352V623Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com6-dc5"
      d="M405 623H352V627H405V623Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc11"
      d="M458 623H405V627H458V623Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc10"
      d="M511 623H458V627H511V623Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc9"
      d="M564 623H511V627H564V623Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc8"
      d="M617 623H564V627H617V623Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc7"
      d="M670 623H617V627H670V623Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com5-dc7"
      d="M352 634H299V638H352V634Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com5-dc8"
      d="M405 634H352V638H405V634Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com5-dc9"
      d="M458 634H405V638H458V634Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc12"
      d="M511 634H458V638H511V634Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc13"
      d="M564 634H511V638H564V634Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc14"
      d="M617 634H564V638H617V634Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc15"
      d="M670 634H617V638H670V634Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com6-dc7"
      d="M352 638H299V642H352V638Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com6-dc8"
      d="M405 638H352V642H405V638Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com6-dc9"
      d="M458 638H405V642H458V638Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc12"
      d="M511 638H458V642H511V638Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc13"
      d="M564 638H511V642H564V638Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc14"
      d="M617 638H564V642H617V638Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc15"
      d="M670 638H617V642H670V638Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com5-dc12"
      d="M358 649H305V653H358V649Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com5-dc11"
      d="M411 649H358V653H411V649Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com5-dc10"
      d="M464 649H411V653H464V649Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc18"
      d="M517 649H464V653H517V649Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc17"
      d="M570 649H517V653H570V649Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc16"
      d="M623 649H570V653H623V649Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com6-dc12"
      d="M358 653H305V657H358V653Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com6-dc11"
      d="M411 653H358V657H411V653Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com6-dc10"
      d="M464 653H411V657H464V653Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc18"
      d="M517 653H464V657H517V653Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc17"
      d="M570 653H517V657H570V653Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc16"
      d="M623 653H570V657H623V653Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com5-dc3"
      d="M352 604H299V608H352V604Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com5-dc4"
      d="M405 604H352V608H405V604Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc5"
      d="M458 604H405V608H458V604Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc6"
      d="M511 604H458V608H511V604Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc18"
      d="M564 604H511V608H564V604Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc19"
      d="M617 604H564V608H617V604Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc20"
      d="M670 604H617V608H670V604Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com6-dc3"
      d="M352 608H299V612H352V608Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com6-dc4"
      d="M405 608H352V612H405V608Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc5"
      d="M458 608H405V612H458V608Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc6"
      d="M511 608H458V612H511V608Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc18"
      d="M564 608H511V612H564V608Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc19"
      d="M617 608H564V612H617V608Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc20"
      d="M670 608H617V612H670V608Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com5-dc1"
      d="M411 574H358V578H411V574Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc1"
      d="M464 574H411V578H464V574Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc2"
      d="M517 574H464V578H517V574Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc12"
      d="M570 574H517V578H570V574Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc13"
      d="M623 574H570V578H623V574Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc14"
      d="M676 574H623V578H676V574Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com6-dc1"
      d="M411 578H358V582H411V578Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc1"
      d="M464 578H411V582H464V578Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc2"
      d="M517 578H464V582H517V578Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc12"
      d="M570 578H517V582H570V578Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc13"
      d="M623 578H570V582H623V578Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc14"
      d="M676 578H623V582H676V578Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com5-dc2"
      d="M409 589H356V593H409V589Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc4"
      d="M462 589H409V593H462V589Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com3-dc3"
      d="M515 589H462V593H515V589Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc17"
      d="M568 589H515V593H568V589Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc16"
      d="M621 589H568V593H621V589Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com1-dc15"
      d="M674 589H621V593H674V589Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com6-dc2"
      d="M409 593H356V597H409V593Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc4"
      d="M462 593H409V597H462V593Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com4-dc3"
      d="M515 593H462V597H515V593Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc17"
      d="M568 593H515V597H568V593Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc16"
      d="M621 593H568V597H621V593Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1-com2-dc15"
      d="M674 593H621V597H674V593Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M385 631H668"
      stroke="#F06730"
    />
    <path
      id="kahrasan-1"
      d="M1025.16 586L912 588.066L912.018 589.066L1025.18 587L1025.16 586Z"
      fill="#F06730"
    />
    <path
      id="kahrasan-1"
      d="M1027.16 670L914 672.066L914.018 673.066L1027.18 671L1027.16 670Z"
      fill="#F06730"
    />
    <path
      id="kahrasan-1"
      d="M1019.93 581.876L1007.93 582.125L1008.08 589.123L1020.07 588.874L1019.93 581.876Z"
      fill="#0A0000"
    />
    <path
      id="kahrasan-1"
      d="M1036 582H1024V589H1036V582Z"
      fill="#0A0000"
    />
    <path
      id="kahrasan-1"
      d="M768.75 737.5V746M913.5 589L915 673L847.5 711L798.5 739H777L768.75 737.5L913.5 589ZM658 576.5L666.5 590.5L668 606L668.5 647.5L685 668.5L707 694L760.5 736L768.75 737.5L658 576.5Z"
      stroke="#333CF6"
      stroke-linecap="round"
    />
    <path
      id="kahrasan-1"
      d="M915 588H911V591H915V588Z"
      fill="#0A0000"
    />
    <path
      id="kahrasan-1"
      d="M916 671H913V674H916V671Z"
      fill="#0A0000"
    />
    <path
      id="kahrasan-1"
      d="M1022 668H1015V673H1022V668Z"
      fill="#0A0000"
    />
    <path
      id="kahrasan-1"
      d="M1032 668H1025V673H1032V668Z"
      fill="#0A0000"
    />
    <path
      id="kahrasan-1"
      d="M968 702H915V706H968V702Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 706H915V710H968V706Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M967 556H914V560H967V556Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1020 556H967V560H1020V556Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1073 556H1020V560H1073V556Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1126 556H1073V560H1126V556Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M967 560H914V564H967V560Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1020 560H967V564H1020V560Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1073 560H1020V564H1073V560Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1126 560H1073V564H1126V560Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M966 570H913V574H966V570Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1019 570H966V574H1019V570Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1072 570H1019V574H1072V570Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1125 570H1072V574H1125V570Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M966 574H913V578H966V574Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1019 574H966V578H1019V574Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1072 574H1019V578H1072V574Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1125 574H1072V578H1125V574Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 591H915V595H968V591Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 591H968V595H1021V591Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 591H1021V595H1074V591Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 591H1074V595H1127V591Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 595H915V599H968V595Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 595H968V599H1021V595Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 595H1021V599H1074V595Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 595H1074V599H1127V595Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 605H915V609H968V605Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 605H968V609H1021V605Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 605H1021V609H1074V605Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 605H1074V609H1127V605Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 609H915V613H968V609Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 609H968V613H1021V609Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 609H1021V613H1074V609Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 609H1074V613H1127V609Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 619H915V623H968V619Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 619H968V623H1021V619Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 619H1021V623H1074V619Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 619H1074V623H1127V619Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 623H915V627H968V623Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 623H968V627H1021V623Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 623H1021V627H1074V623Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 623H1074V627H1127V623Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 633H915V637H968V633Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 633H968V637H1021V633Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 633H1021V637H1074V633Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 633H1074V637H1127V633Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 637H915V641H968V637Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 637H968V641H1021V637Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 637H1021V641H1074V637Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 637H1074V641H1127V637Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 647H915V651H968V647Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 647H968V651H1021V647Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 647H1021V651H1074V647Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 647H1074V651H1127V647Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 651H915V655H968V651Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 651H968V655H1021V651Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 651H1021V655H1074V651Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 651H1074V655H1127V651Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 677H915V681H968V677Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 677H968V681H1021V677Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 677H1021V681H1074V677Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 677H1074V681H1127V677Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 681H915V685H968V681Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 681H968V685H1021V681Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 681H1021V685H1074V681Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 681H1074V685H1127V681Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 689H915V693H968V689Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 689H968V693H1021V689Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 689H1021V693H1074V689Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 689H1074V693H1127V689Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M968 693H915V697H968V693Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1021 693H968V697H1021V693Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1074 693H1021V697H1074V693Z"
      fill="#333CF6"
    />
    <path
      id="kahrasan-1"
      d="M1127 693H1074V697H1127V693Z"
      fill="#333CF6"
    />
    <path
      d="M671 630H666V634H671V630Z"
      fill="#0A0000"
    />
    <path
      d="M385 628H371V634H385V628Z"
      fill="#C4C4C4"
    />
    <path
      d="M406 628H392V634H406V628Z"
      fill="#C4C4C4"
    />
    <path
      d="M534.5 616.5L669 617"
      stroke="#F06730"
    />
    <path
      d="M536 614H525V619H536V614Z"
      fill="#C4C4C4"
    />
    <path
      d="M550 614H539V619H550V614Z"
      fill="#C4C4C4"
    />
    <path
      d="M670 615H665V619H670V615Z"
      fill="#0A0000"
    />
    <path
      d="M641 572.5H660.5"
      stroke="#F06730"
    />
    <path
      d="M641 570H630V574H641V570Z"
      fill="#C4C4C4"
    />
    <path
      d="M655 570H644V574H655V570Z"
      fill="#C4C4C4"
    />
    <path
      d="M665 570H660V574H665V570Z"
      fill="#0A0000"
    />
    <path
      d="M777.5 734.5H761.5V740.5H777.5V734.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M777.5 746.5H761.5V752.5H777.5V746.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M801 736H795V741H801V736Z"
      fill="#0E0000"
    />
    <path
      d="M855 706H849V711H855V706Z"
      fill="#0E0000"
    />
    <path
      d="M1052 989L1019.5 974.5L1043.5 964V929"
      stroke="#333CF6"
    />
    <path
      d="M1211.13 915.998L1208.77 820.088L1209.77 820.063L1212.13 915.974L1211.13 915.998Z"
      fill="#991B1E"
    />
    <path
      d="M1239.46 914.328L1214 914.824L1214.02 915.824L1239.48 915.328L1239.46 914.328Z"
      fill="#991B1E"
    />
    <path
      d="M1277 815.894L1267 816L1267.07 823L1277.07 822.894L1277 815.894Z"
      fill="#0A0000"
    />
    <path
      d="M751.687 759.867L724 760.367M689 761L718 760.476L689 761ZM1128.5 714L1103 714.449L1128.5 714ZM1096.5 714.564L1068.72 715.053L1096.5 714.564ZM1062.3 715.166L1062.16 715.169L1034.74 715.652L1062.3 715.166ZM1028.5 715.762L1001 716.247L1028.5 715.762ZM994.5 716.361L967 716.846L994.5 716.361ZM960.6 716.959L933.071 717.444L960.6 716.959ZM927 717.551L901.5 718L879.844 736.629L927 717.551ZM875 740.796L855 758L826 758.524L875 740.796ZM819.681 758.638L792.151 759.136L819.681 758.638ZM786 759.247L758.212 759.749L786 759.247Z"
      stroke="#29FD2E"
      stroke-linecap="round"
    />
    <path
      d="M1134 897H1130V901H1134V897Z"
      fill="#0A0000"
    />
    <path
      d="M1213 896H1210V899H1213V896Z"
      fill="#0A0000"
    />
    <path
      d="M1214 914H1211V917H1214V914Z"
      fill="#0A0000"
    />
    <path
      d="M1236 910H1227V917H1236V910Z"
      fill="#0A0000"
    />
    <path
      d="M1247 910H1238V917H1247V910Z"
      fill="#0A0000"
    />
    <path
      d="M1067.44 897.305L1050.44 897.697L1050.56 902.696L1067.55 902.304L1067.44 897.305Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1067.51 907.511L1050.51 907.903L1050.63 912.902L1067.62 912.51L1067.51 907.511Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M1045 917L1038 917.231L1038.1 920.229L1045.1 919.999L1045 917Z"
      fill="#C4C4C4"
    />
    <path
      d="M1034 917L1027 917.231L1027.11 920.498L1034.1 920.267L1034 917Z"
      fill="#C4C4C4"
    />
    <path
      d="M1064.5 907.5V901H1048.5L1036.5 917V930L839 933L828 933.5"
      stroke="#333CF6"
      stroke-linecap="round"
    />
    <path
      d="M1039 928H1035V932H1039V928Z"
      fill="black"
    />
    <path
      d="M978 929H974V933H978V929Z"
      fill="black"
    />
    <path
      d="M859 930H855V934H859V930Z"
      fill="black"
    />
    <path
      d="M838.996 932L832 932.231L832.099 935.229L839.095 934.999L838.996 932Z"
      fill="#C4C4C4"
    />
    <path
      d="M827.996 932L821 932.231L821.108 935.498L828.104 935.267L827.996 932Z"
      fill="#C4C4C4"
    />
    <path
      d="M1211.36 897.569L1067 899.567L1067.01 900.567L1211.37 898.569L1211.36 897.569Z"
      fill="#991B1E"
    />
    <path
      id="caglasan-1"
      d="M1127 718H1074V722H1127V718Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1127 722H1074V726H1127V722Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1006 883H953V887H1006V883Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1006 887H953V891H1006V887Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M758 787H705V791H758V787Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M758 791H705V795H758V791Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M762 801H709V805H762V801Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M762 805H709V809H762V805Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M769 815H716V819H769V815Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M769 819H716V823H769V819Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M773 829H720V833H773V829Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M773 833H720V837H773V833Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M750 772H697V776H750V772Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M750 776H697V780H750V776Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M775 843H722V847H775V843Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M775 847H722V851H775V847Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M777 857H724V861H777V857Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M777 861H724V865H777V861Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M785 871H732V875H785V871Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M785 875H732V879H785V875Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1021 732H968V736H1021V732Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1074 732H1021V736H1074V732Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1127 732H1074V736H1127V732Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1021 736H968V740H1021V736Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1074 736H1021V740H1074V736Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1127 736H1074V740H1127V736Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1021 746H968V750H1021V746Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1074 746H1021V750H1074V746Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1127 746H1074V750H1127V746Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1021 750H968V754H1021V750Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1074 750H1021V754H1074V750Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1127 750H1074V754H1127V750Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1019 760H966V764H1019V760Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1072 760H1019V764H1072V760Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1125 760H1072V764H1125V760Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1019 764H966V768H1019V764Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1072 764H1019V768H1072V764Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1125 764H1072V768H1125V764Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1185.94 945.865L1132.95 946.729L1133.02 950.728L1186.01 949.864L1185.94 945.865Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1238.93 945.001L1185.94 945.865L1186.01 949.864L1239 949L1238.93 945.001Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1291.92 944.136L1238.93 945L1239 948.999L1291.99 948.135L1291.92 944.136Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1344.92 943.272L1291.93 944.136L1292 948.135L1344.99 947.271L1344.92 943.272Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1186 949.864L1133.01 950.728L1133.08 954.727L1186.07 953.863L1186 949.864Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1239 949L1186.01 949.864L1186.08 953.863L1239.07 952.999L1239 949Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1291.99 948.136L1239 949L1239.07 952.999L1292.06 952.135L1291.99 948.136Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1344.98 947.272L1291.99 948.136L1292.06 952.135L1345.05 951.271L1344.98 947.272Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1185.99 931.593L1133 932.457L1133.07 936.456L1186.06 935.592L1185.99 931.593Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1238.98 930.728L1185.99 931.592L1186.06 935.591L1239.05 934.727L1238.98 930.728Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1291.98 929.864L1238.99 930.728L1239.06 934.727L1292.05 933.863L1291.98 929.864Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1344.97 929L1291.98 929.864L1292.05 933.863L1345.04 932.999L1344.97 929Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1186.06 935.592L1133.07 936.456L1133.14 940.455L1186.13 939.591L1186.06 935.592Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1239.05 934.728L1186.06 935.592L1186.13 939.591L1239.12 938.727L1239.05 934.728Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1292.04 933.864L1239.05 934.728L1239.12 938.727L1292.11 937.863L1292.04 933.864Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1345.03 933L1292.04 933.864L1292.11 937.863L1345.1 936.999L1345.03 933Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1183.99 917.593L1131 918.457L1131.07 922.456L1184.06 921.592L1183.99 917.593Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1236.98 916.728L1183.99 917.592L1184.06 921.591L1237.05 920.727L1236.98 916.728Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1289.98 915.864L1236.99 916.728L1237.06 920.727L1290.05 919.863L1289.98 915.864Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1342.97 915L1289.98 915.864L1290.05 919.863L1343.04 918.999L1342.97 915Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1184.06 921.592L1131.07 922.456L1131.14 926.455L1184.13 925.591L1184.06 921.592Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1237.05 920.728L1184.06 921.592L1184.13 925.591L1237.12 924.727L1237.05 920.728Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1290.04 919.864L1237.05 920.728L1237.12 924.727L1290.11 923.863L1290.04 919.864Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1343.03 919L1290.04 919.864L1290.11 923.863L1343.1 922.999L1343.03 919Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1183.99 899.593L1131 900.457L1131.07 904.456L1184.06 903.592L1183.99 899.593Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1236.98 898.728L1183.99 899.592L1184.06 903.591L1237.05 902.727L1236.98 898.728Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1289.98 897.864L1236.99 898.728L1237.06 902.727L1290.05 901.863L1289.98 897.864Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1342.97 897L1289.98 897.864L1290.05 901.863L1343.04 900.999L1342.97 897Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1184.06 903.592L1131.07 904.456L1131.14 908.455L1184.13 907.591L1184.06 903.592Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1237.05 902.728L1184.06 903.592L1184.13 907.591L1237.12 906.727L1237.05 902.728Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1290.04 901.864L1237.05 902.728L1237.12 906.727L1290.11 905.863L1290.04 901.864Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1343.03 901L1290.04 901.864L1290.11 905.863L1343.1 904.999L1343.03 901Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1183.99 885.593L1131 886.457L1131.07 890.456L1184.06 889.592L1183.99 885.593Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1236.98 884.728L1183.99 885.592L1184.06 889.591L1237.05 888.727L1236.98 884.728Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1289.98 883.864L1236.99 884.728L1237.06 888.727L1290.05 887.863L1289.98 883.864Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1342.97 883L1289.98 883.864L1290.05 887.863L1343.04 886.999L1342.97 883Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1184.06 889.592L1131.07 890.456L1131.14 894.455L1184.13 893.591L1184.06 889.592Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1237.05 888.728L1184.06 889.592L1184.13 893.591L1237.12 892.727L1237.05 888.728Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1290.04 887.864L1237.05 888.728L1237.12 892.727L1290.11 891.863L1290.04 887.864Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1343.03 887L1290.04 887.864L1290.11 891.863L1343.1 890.999L1343.03 887Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1182.99 872.593L1130 873.457L1130.07 877.456L1183.06 876.592L1182.99 872.593Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1235.98 871.728L1182.99 872.592L1183.06 876.591L1236.05 875.727L1235.98 871.728Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1288.98 870.864L1235.99 871.728L1236.06 875.727L1289.05 874.863L1288.98 870.864Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1341.97 870L1288.98 870.864L1289.05 874.863L1342.04 873.999L1341.97 870Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1183.06 876.592L1130.07 877.456L1130.14 881.455L1183.13 880.591L1183.06 876.592Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1236.05 875.728L1183.06 876.592L1183.13 880.591L1236.12 879.727L1236.05 875.728Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1289.04 874.864L1236.05 875.728L1236.12 879.727L1289.11 878.863L1289.04 874.864Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1342.03 874L1289.04 874.864L1289.11 878.863L1342.1 877.999L1342.03 874Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1182.99 857.593L1130 858.457L1130.07 862.456L1183.06 861.592L1182.99 857.593Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1235.98 856.728L1182.99 857.592L1183.06 861.591L1236.05 860.727L1235.98 856.728Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1288.98 855.864L1235.99 856.728L1236.06 860.727L1289.05 859.863L1288.98 855.864Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1341.97 855L1288.98 855.864L1289.05 859.863L1342.04 858.999L1341.97 855Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1183.06 861.592L1130.07 862.456L1130.14 866.455L1183.13 865.591L1183.06 861.592Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1236.05 860.728L1183.06 861.592L1183.13 865.591L1236.12 864.727L1236.05 860.728Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1289.04 859.864L1236.05 860.728L1236.12 864.727L1289.11 863.863L1289.04 859.864Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1342.03 859L1289.04 859.864L1289.11 863.863L1342.1 862.999L1342.03 859Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1048.5 900.5V786V784.5H1069.5"
      stroke="#991B1E"
    />
    <path
      id="caglasan-1"
      d="M1065.68 779.176L1051.97 779.188L1051.98 789.278L1065.69 789.266L1065.68 779.176Z"
      fill="#0A0000"
    />
    <path
      id="caglasan-1"
      d="M1050 783L1047 783.067L1047.07 786.066L1050.07 785.999L1050 783Z"
      fill="#696666"
    />
    <path
      id="caglasan-1"
      d="M1016 789H963V793H1016V789Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1069 789H1016V793H1069V789Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1122 789H1069V793H1122V789Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1016 793H963V797H1016V793Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1069 793H1016V797H1069V793Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1122 793H1069V797H1122V793Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1016 803H963V807H1016V803Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1069 803H1016V807H1069V803Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1122 803H1069V807H1122V803Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1016 807H963V811H1016V807Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1069 807H1016V811H1069V807Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1122 807H1069V811H1122V807Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1016 817H963V821H1016V817Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1069 817H1016V821H1069V817Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1122 817H1069V821H1122V817Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1016 821H963V825H1016V821Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1069 821H1016V825H1069V821Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1122 821H1069V825H1122V821Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1016 831H963V835H1016V831Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1069 831H1016V835H1069V831Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1122 831H1069V835H1122V831Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1016 835H963V839H1016V835Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1069 835H1016V839H1069V835Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1122 835H1069V839H1122V835Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M802 966H749V970H802V966Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M855 966H802V970H855V966Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M908 966H855V970H908V966Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M802 970H749V974H802V970Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M855 970H802V974H855V970Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M908 970H855V974H908V970Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M971 936H918V940H971V936Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1024 936H971V940H1024V936Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1077 936H1024V940H1077V936Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M971 940H918V944H971V940Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1024 940H971V944H1024V940Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1077 940H1024V944H1077V940Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1208.94 840.414L1155.95 841.241L1156.01 845.241L1209.01 844.414L1208.94 840.414Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1261.93 839.587L1208.94 840.414L1209 844.414L1262 843.587L1261.93 839.587Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1314.92 838.76L1261.93 839.587L1261.99 843.586L1314.99 842.759L1314.92 838.76Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1209 844.413L1156.01 845.24L1156.07 849.24L1209.07 848.413L1209 844.413Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1261.99 843.586L1209 844.413L1209.06 848.413L1262.06 847.586L1261.99 843.586Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1314.99 842.759L1262 843.586L1262.06 847.586L1315.06 846.759L1314.99 842.759Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1006 855H953V859H1006V855Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1059 855H1006V859H1059V855Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1006 859H953V863H1006V859Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1059 859H1006V863H1059V859Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M794 898H741V902H794V898Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M847 898H794V902H847V898Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M794 902H741V906H794V902Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M847 902H794V906H847V902Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M799 912H746V916H799V912Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M852 912H799V916H852V912Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M799 916H746V920H799V916Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M852 916H799V920H852V916Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M805 938H752V942H805V938Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M858 938H805V942H858V938Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M805 942H752V946H805V942Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M858 942H805V946H858V942Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M805 952H752V956H805V952Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M858 952H805V956H858V952Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M805 956H752V960H805V956Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M858 956H805V960H858V956Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1006 869H953V873H1006V869Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1059 869H1006V873H1059V869Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1006 873H953V877H1006V873Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1059 873H1006V877H1059V873Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1264 745H1211V749H1264V745Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1317 745H1264V749H1317V745Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1264 749H1211V753H1264V749Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1317 749H1264V753H1317V749Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1264 758H1211V762H1264V758Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1317 758H1264V762H1317V758Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1264 762H1211V766H1264V762Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1317 762H1264V766H1317V762Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1264 772H1211V776H1264V772Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1317 772H1264V776H1317V772Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1264 776H1211V780H1264V776Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1317 776H1264V780H1317V776Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1264 784H1211V788H1264V784Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1317 784H1264V788H1317V784Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1264 788H1211V792H1264V788Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1317 788H1264V792H1317V788Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1263 800H1210V804H1263V800Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1316 800H1263V804H1316V800Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1263 804H1210V808H1263V804Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1316 804H1263V808H1316V804Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1263 824H1210V828H1263V824Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1316 824H1263V828H1316V824Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1263 828H1210V832H1263V828Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1316 828H1263V832H1316V828Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1020 921H967V925H1020V921Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1073 921H1020V925H1073V921Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1020 925H967V929H1020V925Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1073 925H1020V929H1073V925Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1022 950H969V954H1022V950Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1075 950H1022V954H1075V950Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1022 954H969V958H1022V954Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1075 954H1022V958H1075V954Z"
      fill="#F06730"
    />
    <path
      id="caglasan-1"
      d="M1264 816L1254 816.106L1254.07 823.106L1264.07 823L1264 816Z"
      fill="#0A0000"
    />
    <path
      id="caglasan-1"
      d="M1643 1134H1655"
      stroke="#F06730"
    />
    <path
      d="M1886.5 972H1382"
      stroke="#29FD2E"
    />
    <path
      id="eflinsan-2"
      d="M1659 1271H1606V1275H1659V1271Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1659 1275H1606V1279H1659V1275Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1413.5 1035V1141.5L1421 1151H1461.5V1227.5L1490.5 1241V1280.5V1284.5H1559"
      stroke="#29FD2E"
    />
    <path
      id="eflinsan-2"
      d="M1552 1271H1499V1275H1552V1271Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1552 1275H1499V1279H1552V1275Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1445 982H1392V986H1445V982Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1498 982H1445V986H1498V982Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1551 982H1498V986H1551V982Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1604 982H1551V986H1604V982Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1445 986H1392V990H1445V986Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1498 986H1445V990H1498V986Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1551 986H1498V990H1551V986Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1604 986H1551V990H1604V986Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1809 982H1756V986H1809V982Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1862 982H1809V986H1862V982Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1809 986H1756V990H1809V986Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1862 986H1809V990H1862V986Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1441 999H1388V1003H1441V999Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1494 999H1441V1003H1494V999Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1547 999H1494V1003H1547V999Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1600 999H1547V1003H1600V999Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1653 999H1600V1003H1653V999Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1706 999H1653V1003H1706V999Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1759 999H1706V1003H1759V999Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1812 999H1759V1003H1812V999Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1865 999H1812V1003H1865V999Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1441 1003H1388V1007H1441V1003Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1494 1003H1441V1007H1494V1003Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1547 1003H1494V1007H1547V1003Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1600 1003H1547V1007H1600V1003Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1653 1003H1600V1007H1653V1003Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1706 1003H1653V1007H1706V1003Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1759 1003H1706V1007H1759V1003Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1812 1003H1759V1007H1812V1003Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1865 1003H1812V1007H1865V1003Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1483 1016H1430V1020H1483V1016Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1536 1016H1483V1020H1536V1016Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1589 1016H1536V1020H1589V1016Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1642 1016H1589V1020H1642V1016Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1695 1016H1642V1020H1695V1016Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1748 1016H1695V1020H1748V1016Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1801 1016H1748V1020H1801V1016Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1854 1016H1801V1020H1854V1016Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1483 1020H1430V1024H1483V1020Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1536 1020H1483V1024H1536V1020Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1589 1020H1536V1024H1589V1020Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1642 1020H1589V1024H1642V1020Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1695 1020H1642V1024H1695V1020Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1748 1020H1695V1024H1748V1020Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1801 1020H1748V1024H1801V1020Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1854 1020H1801V1024H1854V1020Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1488 1033H1435V1037H1488V1033Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1541 1033H1488V1037H1541V1033Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1594 1033H1541V1037H1594V1033Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1647 1033H1594V1037H1647V1033Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1700 1033H1647V1037H1700V1033Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1753 1033H1700V1037H1753V1033Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1806 1033H1753V1037H1806V1033Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1488 1037H1435V1041H1488V1037Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1541 1037H1488V1041H1541V1037Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1594 1037H1541V1041H1594V1037Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1647 1037H1594V1041H1647V1037Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1700 1037H1647V1041H1700V1037Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1753 1037H1700V1041H1753V1037Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1806 1037H1753V1041H1806V1037Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1478 1050H1425V1054H1478V1050Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1531 1050H1478V1054H1531V1050Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1584 1050H1531V1054H1584V1050Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1637 1050H1584V1054H1637V1050Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1690 1050H1637V1054H1690V1050Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1743 1050H1690V1054H1743V1050Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1478 1054H1425V1058H1478V1054Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1531 1054H1478V1058H1531V1054Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1584 1054H1531V1058H1584V1054Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1637 1054H1584V1058H1637V1054Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1690 1054H1637V1058H1690V1054Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1743 1054H1690V1058H1743V1054Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1482 1067H1429V1071H1482V1067Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1535 1067H1482V1071H1535V1067Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1588 1067H1535V1071H1588V1067Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1641 1067H1588V1071H1641V1067Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1694 1067H1641V1071H1694V1067Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1747 1067H1694V1071H1747V1067Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1482 1071H1429V1075H1482V1071Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1535 1071H1482V1075H1535V1071Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1588 1071H1535V1075H1588V1071Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1641 1071H1588V1075H1641V1071Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1694 1071H1641V1075H1694V1071Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1747 1071H1694V1075H1747V1071Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1481 1084H1428V1088H1481V1084Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1534 1084H1481V1088H1534V1084Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1587 1084H1534V1088H1587V1084Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1640 1084H1587V1088H1640V1084Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1693 1084H1640V1088H1693V1084Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1746 1084H1693V1088H1746V1084Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1481 1088H1428V1092H1481V1088Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1534 1088H1481V1092H1534V1088Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1587 1088H1534V1092H1587V1088Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1640 1088H1587V1092H1640V1088Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1693 1088H1640V1092H1693V1088Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1746 1088H1693V1092H1746V1088Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1472 1118H1419V1122H1472V1118Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1525 1118H1472V1122H1525V1118Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1578 1118H1525V1122H1578V1118Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1631 1118H1578V1122H1631V1118Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1684 1118H1631V1122H1684V1118Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1737 1118H1684V1122H1737V1118Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1472 1122H1419V1126H1472V1122Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1525 1122H1472V1126H1525V1122Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1578 1122H1525V1126H1578V1122Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1631 1122H1578V1126H1631V1122Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1684 1122H1631V1126H1684V1122Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1737 1122H1684V1126H1737V1122Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1474 1135H1421V1139H1474V1135Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1527 1135H1474V1139H1527V1135Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1580 1135H1527V1139H1580V1135Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1633 1135H1580V1139H1633V1135Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1686 1135H1633V1139H1686V1135Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1739 1135H1686V1139H1739V1135Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1474 1139H1421V1143H1474V1139Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1527 1139H1474V1143H1527V1139Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1580 1139H1527V1143H1580V1139Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1633 1139H1580V1143H1633V1139Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1686 1139H1633V1143H1686V1139Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1739 1139H1686V1143H1739V1139Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1521 1152H1468V1156H1521V1152Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1574 1152H1521V1156H1574V1152Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1627 1152H1574V1156H1627V1152Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1680 1152H1627V1156H1680V1152Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1733 1152H1680V1156H1733V1152Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1521 1156H1468V1160H1521V1156Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1574 1156H1521V1160H1574V1156Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1627 1156H1574V1160H1627V1156Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1680 1156H1627V1160H1680V1156Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1733 1156H1680V1160H1733V1156Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1523 1169H1470V1173H1523V1169Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1576 1169H1523V1173H1576V1169Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1629 1169H1576V1173H1629V1169Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1682 1169H1629V1173H1682V1169Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1735 1169H1682V1173H1735V1169Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1523 1173H1470V1177H1523V1173Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1576 1173H1523V1177H1576V1173Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1629 1173H1576V1177H1629V1173Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1682 1173H1629V1177H1682V1173Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1735 1173H1682V1177H1735V1173Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1523 1186H1470V1190H1523V1186Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1576 1186H1523V1190H1576V1186Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1629 1186H1576V1190H1629V1186Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1682 1186H1629V1190H1682V1186Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1735 1186H1682V1190H1735V1186Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1523 1190H1470V1194H1523V1190Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1576 1190H1523V1194H1576V1190Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1629 1190H1576V1194H1629V1190Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1682 1190H1629V1194H1682V1190Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1735 1190H1682V1194H1735V1190Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1523 1220H1470V1224H1523V1220Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1576 1220H1523V1224H1576V1220Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1629 1220H1576V1224H1629V1220Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1682 1220H1629V1224H1682V1220Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1523 1224H1470V1228H1523V1224Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1576 1224H1523V1228H1576V1224Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1629 1224H1576V1228H1629V1224Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1682 1224H1629V1228H1682V1224Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1514 1203H1461V1207H1514V1203Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1567 1203H1514V1207H1567V1203Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1620 1203H1567V1207H1620V1203Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1673 1203H1620V1207H1673V1203Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1514 1207H1461V1211H1514V1207Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1567 1207H1514V1211H1567V1207Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1620 1207H1567V1211H1620V1207Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1673 1207H1620V1211H1673V1207Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1548 1237H1495V1241H1548V1237Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1601 1237H1548V1241H1601V1237Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1654 1237H1601V1241H1654V1237Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1548 1241H1495V1245H1548V1241Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1601 1241H1548V1245H1601V1241Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1654 1241H1601V1245H1654V1241Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1551 1254H1498V1258H1551V1254Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1604 1254H1551V1258H1604V1254Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1657 1254H1604V1258H1657V1254Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1551 1258H1498V1262H1551V1258Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1604 1258H1551V1262H1604V1258Z"
      fill="#FAFD6E"
    />
    <path
      id="eflinsan-2"
      d="M1657 1258H1604V1262H1657V1258Z"
      fill="#FAFD6E"
    />
    <path
      d="M1565 1215H1551V1220H1565V1215Z"
      fill="#C4C4C4"
    />
    <path
      d="M1565 1218H1656.5"
      stroke="#F06730"
    />
    <path
      d="M1659 1217H1655V1220H1659V1217Z"
      fill="#0A0000"
    />
    <path
      d="M1659 1132H1655V1135H1659V1132Z"
      fill="#0A0000"
    />
    <path
      d="M1583 1215H1569V1220H1583V1215Z"
      fill="#C4C4C4"
    />
    <path
      d="M1643 1132H1634V1135H1643V1132Z"
      fill="#C4C4C4"
    />
    <path
      d="M1654 1132H1645V1135H1654V1132Z"
      fill="#C4C4C4"
    />
    <path
      d="M1507 1096H1496V1101H1507V1096Z"
      fill="#C4C4C4"
    />
    <path
      d="M1506.5 1099L1656.5 1098"
      stroke="#F06730"
    />
    <path
      d="M1659 1097H1655V1101H1659V1097Z"
      fill="#0A0000"
    />
    <path
      d="M1523 1096H1512V1101H1523V1096Z"
      fill="#C4C4C4"
    />
    <path
      d="M1527 1011H1512V1016H1527V1011Z"
      fill="#C4C4C4"
    />
    <path
      d="M1527 1014H1656.5"
      stroke="#F06730"
    />
    <path
      d="M1659 1029H1655V1033H1659V1029Z"
      fill="#0A0000"
    />
    <path
      d="M1658.5 1031L1747.5 1030.5"
      stroke="#F06730"
    />
    <path
      d="M1548 1011H1533V1016H1548V1011Z"
      fill="#C4C4C4"
    />
    <path
      d="M1741 1028H1730V1033H1741V1028Z"
      fill="#C4C4C4"
    />
    <path
      d="M1758 1028H1747V1033H1758V1028Z"
      fill="#C4C4C4"
    />
    <path
      d="M1656.5 830.5V1222.5"
      stroke="#333CF6"
    />
    <path
      d="M1657.5 838H1860.5"
      stroke="#F06730"
    />
    <path
      id="elvinsan-2"
      d="M1445 958H1392V962H1445V958Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1498 958H1445V962H1498V958Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1551 958H1498V962H1551V958Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1604 958H1551V962H1604V958Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1445 962H1392V966H1445V962Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1498 962H1445V966H1498V962Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1551 962H1498V966H1551V962Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1604 962H1551V966H1604V962Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1779 958H1726V962H1779V958Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1832 958H1779V962H1832V958Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1885 958H1832V962H1885V958Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1779 962H1726V966H1779V962Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1832 962H1779V966H1832V962Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1885 962H1832V966H1885V962Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1544 822H1491V826H1544V822Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1597 822H1544V826H1597V822Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1650 822H1597V826H1650V822Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1703 822H1650V826H1703V822Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1756 822H1703V826H1756V822Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1809 822H1756V826H1809V822Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1862 822H1809V826H1862V822Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1915 822H1862V826H1915V822Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1544 826H1491V830H1544V826Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1597 826H1544V830H1597V826Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1650 826H1597V830H1650V826Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1703 826H1650V830H1703V826Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1756 826H1703V830H1756V826Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1809 826H1756V830H1809V826Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1862 826H1809V830H1862V826Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1915 826H1862V830H1915V826Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1435 840H1382V844H1435V840Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1488 840H1435V844H1488V840Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1541 840H1488V844H1541V840Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1594 840H1541V844H1594V840Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1647 840H1594V844H1647V840Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1700 840H1647V844H1700V840Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1753 840H1700V844H1753V840Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1806 840H1753V844H1806V840Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1859 840H1806V844H1859V840Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1912 840H1859V844H1912V840Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1435 844H1382V848H1435V844Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1488 844H1435V848H1488V844Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1541 844H1488V848H1541V844Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1594 844H1541V848H1594V844Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1647 844H1594V848H1647V844Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1700 844H1647V848H1700V844Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1753 844H1700V848H1753V844Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1806 844H1753V848H1806V844Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1859 844H1806V848H1859V844Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1912 844H1859V848H1912V844Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1415 857H1362V861H1415V857Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1468 857H1415V861H1468V857Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1521 857H1468V861H1521V857Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1574 857H1521V861H1574V857Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1627 857H1574V861H1627V857Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1680 857H1627V861H1680V857Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1733 857H1680V861H1733V857Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1786 857H1733V861H1786V857Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1839 857H1786V861H1839V857Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1892 857H1839V861H1892V857Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1415 861H1362V865H1415V861Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1468 861H1415V865H1468V861Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1521 861H1468V865H1521V861Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1574 861H1521V865H1574V861Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1627 861H1574V865H1627V861Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1680 861H1627V865H1680V861Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1733 861H1680V865H1733V861Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1786 861H1733V865H1786V861Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1839 861H1786V865H1839V861Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1892 861H1839V865H1892V861Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1419 874H1366V878H1419V874Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1472 874H1419V878H1472V874Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1525 874H1472V878H1525V874Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1578 874H1525V878H1578V874Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1631 874H1578V878H1631V874Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1684 874H1631V878H1684V874Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1737 874H1684V878H1737V874Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1790 874H1737V878H1790V874Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1843 874H1790V878H1843V874Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1896 874H1843V878H1896V874Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1419 878H1366V882H1419V878Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1472 878H1419V882H1472V878Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1525 878H1472V882H1525V878Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1578 878H1525V882H1578V878Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1631 878H1578V882H1631V878Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1684 878H1631V882H1684V878Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1737 878H1684V882H1737V878Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1790 878H1737V882H1790V878Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1843 878H1790V882H1843V878Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1896 878H1843V882H1896V878Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1416 891H1363V895H1416V891Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1469 891H1416V895H1469V891Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1522 891H1469V895H1522V891Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1575 891H1522V895H1575V891Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1628 891H1575V895H1628V891Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1681 891H1628V895H1681V891Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1734 891H1681V895H1734V891Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1787 891H1734V895H1787V891Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1840 891H1787V895H1840V891Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1893 891H1840V895H1893V891Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1416 895H1363V899H1416V895Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1469 895H1416V899H1469V895Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1522 895H1469V899H1522V895Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1575 895H1522V899H1575V895Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1628 895H1575V899H1628V895Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1681 895H1628V899H1681V895Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1734 895H1681V899H1734V895Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1787 895H1734V899H1787V895Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1840 895H1787V899H1840V895Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1893 895H1840V899H1893V895Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1416 907H1363V911H1416V907Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1469 907H1416V911H1469V907Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1522 907H1469V911H1522V907Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1575 907H1522V911H1575V907Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1628 907H1575V911H1628V907Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1681 907H1628V911H1681V907Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1734 907H1681V911H1734V907Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1787 907H1734V911H1787V907Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1840 907H1787V911H1840V907Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1893 907H1840V911H1893V907Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1416 911H1363V915H1416V911Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1469 911H1416V915H1469V911Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1522 911H1469V915H1522V911Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1575 911H1522V915H1575V911Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1628 911H1575V915H1628V911Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1681 911H1628V915H1681V911Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1734 911H1681V915H1734V911Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1787 911H1734V915H1787V911Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1840 911H1787V915H1840V911Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1893 911H1840V915H1893V911Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1427 927H1374V931H1427V927Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1480 927H1427V931H1480V927Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1533 927H1480V931H1533V927Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1586 927H1533V931H1586V927Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1639 927H1586V931H1639V927Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1692 927H1639V931H1692V927Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1745 927H1692V931H1745V927Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1798 927H1745V931H1798V927Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1851 927H1798V931H1851V927Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1904 927H1851V931H1904V927Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1427 931H1374V935H1427V931Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1480 931H1427V935H1480V931Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1533 931H1480V935H1533V931Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1586 931H1533V935H1586V931Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1639 931H1586V935H1639V931Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1692 931H1639V935H1692V931Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1745 931H1692V935H1745V931Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1798 931H1745V935H1798V931Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1851 931H1798V935H1851V931Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1904 931H1851V935H1904V931Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1441 943H1388V947H1441V943Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1494 943H1441V947H1494V943Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1547 943H1494V947H1547V943Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1600 943H1547V947H1600V943Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1653 943H1600V947H1653V943Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1706 943H1653V947H1706V943Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1759 943H1706V947H1759V943Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1812 943H1759V947H1812V943Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1865 943H1812V947H1865V943Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1441 947H1388V951H1441V947Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1494 947H1441V951H1494V947Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1547 947H1494V951H1547V947Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1600 947H1547V951H1600V947Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1653 947H1600V951H1653V947Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1706 947H1653V951H1706V947Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1759 947H1706V951H1759V947Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1812 947H1759V951H1812V947Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1865 947H1812V951H1865V947Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1599 806H1546V810H1599V806Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1652 806H1599V810H1652V806Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1705 806H1652V810H1705V806Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1758 806H1705V810H1758V806Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1811 806H1758V810H1811V806Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1864 806H1811V810H1864V806Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1917 806H1864V810H1917V806Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1599 810H1546V814H1599V810Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1652 810H1599V814H1652V810Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1705 810H1652V814H1705V810Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1758 810H1705V814H1758V810Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1811 810H1758V814H1811V810Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1864 810H1811V814H1864V810Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1917 810H1864V814H1917V810Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1652 789H1599V793H1652V789Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1705 789H1652V793H1705V789Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1758 789H1705V793H1758V789Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1811 789H1758V793H1811V789Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1864 789H1811V793H1864V789Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1917 789H1864V793H1917V789Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1652 793H1599V797H1652V793Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1705 793H1652V797H1705V793Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1758 793H1705V797H1758V793Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1811 793H1758V797H1811V793Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1864 793H1811V797H1864V793Z"
      fill="#F08871"
    />
    <path
      id="elvinsan-2"
      d="M1917 793H1864V797H1917V793Z"
      fill="#F08871"
    />
    <path
      d="M1772 920H1753V927H1772V920Z"
      fill="#C4C4C4"
    />
    <path
      d="M1798 920H1779V927H1798V920Z"
      fill="#C4C4C4"
    />
    <path
      d="M1778.5 924.5H1656.5"
      stroke="#F06730"
    />
    <path
      d="M1610 885H1597V891H1610V885Z"
      fill="#C4C4C4"
    />
    <path
      d="M1627 885H1614V891H1627V885Z"
      fill="#C4C4C4"
    />
    <path
      d="M1659 836H1655V840H1659V836Z"
      fill="#0A0000"
    />
    <path
      d="M1659 818H1655V822H1659V818Z"
      fill="#0A0000"
    />
    <path
      d="M1655 821H1638.5"
      stroke="#F06730"
    />
    <path
      d="M1639 819H1631V822H1639V819Z"
      fill="#C4C4C4"
    />
    <path
      d="M1651 819H1643V822H1651V819Z"
      fill="#C4C4C4"
    />
    <path
      d="M1852 835H1838V840H1852V835Z"
      fill="#C4C4C4"
    />
    <path
      d="M1872 835H1858V840H1872V835Z"
      fill="#C4C4C4"
    />
    <path
      d="M1659 923H1655V927H1659V923Z"
      fill="#0A0000"
    />
    <path
      d="M1659 903H1655V907H1659V903Z"
      fill="#0A0000"
    />
    <path
      d="M1707.5 994L1702.5 999M2364.5 1080.5L2359.5 1070.5L2279.5 1054.5L2219.5 1056L2176 966.5L2098 891.5L2025 830L1940 805.5L1933.5 857.5L1919 876.5L1913.5 962L1882 988.5H1863H1743L1707.5 994L2364.5 1080.5ZM1707.5 994H1696H1707.5Z"
      stroke="#2CFFFE"
    />
    <path
      d="M2356 1105C2357.2 1105 2502.5 1104.33 2575 1104"
      stroke="#F06730"
    />
    <path
      d="M2394 1266H2323"
      stroke="#F06730"
    />
    <path
      d="M2381 1083H2395V1246"
      stroke="#333CF6"
    />
    <path
      d="M2413 910H2493"
      stroke="#F46024"
    />
    <path
      d="M2390 990H2534"
      stroke="#F46024"
    />
    <path
      d="M2307 832L2327 909.559L2376.5 920L2410 909.559"
      stroke="#333CF6"
    />
    <path
      d="M2368.29 950L2331 1004M2369.8 924H2376.86L2376.35 941H2368.29V950L2369.8 924ZM2368.29 950L2395 1004L2368.29 950Z"
      stroke="#333CF6"
    />
    <path
      id="abaysan-2"
      d="M2302 1302H2249V1306H2302V1302Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2302 1306H2249V1310H2302V1306Z"
      fill="#585651"
    />
    <path
      id="seçilsan-2"
      d="M2549 864H2496V868H2549V864Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2549 868H2496V872H2549V868Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2544 848H2491V852H2544V848Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2544 852H2491V856H2544V852Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2539 832H2486V836H2539V832Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2539 836H2486V840H2539V836Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2535 816H2482V820H2535V816Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2535 820H2482V824H2535V820Z"
      fill="#E62D43"
    />
    <path
      id="abaysan-2"
      d="M2268 1274H2215V1278H2268V1274Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2321 1274H2268V1278H2321V1274Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2374 1274H2321V1278H2374V1274Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2268 1278H2215V1282H2268V1278Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2321 1278H2268V1282H2321V1278Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2374 1278H2321V1282H2374V1278Z"
      fill="#585651"
    />
    <path
      id="seçilsan-2"
      d="M2524 1073H2471V1077H2524V1073Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2577 1073H2524V1077H2577V1073Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2630 1073H2577V1077H2630V1073Z"
      fill="#E62D43"
    />
    <path
      d="M2524 1077H2471V1081H2524V1077Z"
      fill="#E52D43"
    />
    <path
      d="M2577 1077H2524V1081H2577V1077Z"
      fill="#E52D43"
    />
    <path
      d="M2630 1077H2577V1081H2630V1077Z"
      fill="#E52D43"
    />
    <path
      id="seçilsan-2"
      d="M2471 928H2418V932H2471V928Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2524 928H2471V932H2524V928Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2577 928H2524V932H2577V928Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2471 932H2418V936H2471V932Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2524 932H2471V936H2524V932Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2577 932H2524V936H2577V932Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2463 912H2410V916H2463V912Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2516 912H2463V916H2516V912Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2569 912H2516V916H2569V912Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2463 916H2410V920H2463V916Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2516 916H2463V920H2516V916Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2569 916H2516V920H2569V916Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2458 896H2405V900H2458V896Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2511 896H2458V900H2511V896Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2564 896H2511V900H2564V896Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2458 900H2405V904H2458V900Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2511 900H2458V904H2511V900Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2564 900H2511V904H2564V900Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2213 897H2160V901H2213V897Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2266 897H2213V901H2266V897Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2319 897H2266V901H2319V897Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2213 901H2160V905H2213V901Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2266 901H2213V905H2266V901Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2319 901H2266V905H2319V901Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2214 913H2161V917H2214V913Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2267 913H2214V917H2267V913Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2320 913H2267V917H2320V913Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2214 917H2161V921H2214V917Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2267 917H2214V921H2267V917Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2320 917H2267V921H2320V917Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2214 929H2161V933H2214V929Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2267 929H2214V933H2267V929Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2320 929H2267V933H2320V929Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2214 933H2161V937H2214V933Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2267 933H2214V937H2267V933Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2320 933H2267V937H2320V933Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2221 945H2168V949H2221V945Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2274 945H2221V949H2274V945Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2327 945H2274V949H2327V945Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2221 949H2168V953H2221V949Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2274 949H2221V953H2274V949Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2327 949H2274V953H2327V949Z"
      fill="#7CAD14"
    />
    <path
      id="abaysan-2"
      d="M2280 1288H2227V1292H2280V1288Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2333 1288H2280V1292H2333V1288Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2280 1292H2227V1296H2280V1292Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2333 1292H2280V1296H2333V1292Z"
      fill="#585651"
    />
    <path
      id="seçilsan-2"
      d="M2508 880H2455V884H2508V880Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2561 880H2508V884H2561V880Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2508 884H2455V888H2508V884Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2561 884H2508V888H2561V884Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2259 961H2206V965H2259V961Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2312 961H2259V965H2312V961Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2259 965H2206V969H2259V965Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2312 965H2259V969H2312V965Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2260 977H2207V981H2260V977Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2313 977H2260V981H2313V977Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2260 981H2207V985H2260V981Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2313 981H2260V985H2313V981Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2260 993H2207V997H2260V993Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2313 993H2260V997H2313V993Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2260 997H2207V1001H2260V997Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2313 997H2260V1001H2313V997Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2276 1009H2223V1013H2276V1009Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2329 1009H2276V1013H2329V1009Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2276 1013H2223V1017H2276V1013Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2329 1013H2276V1017H2329V1013Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2276 1025H2223V1029H2276V1025Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2329 1025H2276V1029H2329V1025Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2276 1029H2223V1033H2276V1029Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2329 1029H2276V1033H2329V1029Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2276 1041H2223V1045H2276V1041Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2329 1041H2276V1045H2329V1041Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2276 1045H2223V1049H2276V1045Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2329 1045H2276V1049H2329V1045Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2141 833H2088V837H2141V833Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2194 833H2141V837H2194V833Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2247 833H2194V837H2247V833Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2300 833H2247V837H2300V833Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2141 837H2088V841H2141V837Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2194 837H2141V841H2194V837Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2247 837H2194V841H2247V837Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2300 837H2247V841H2300V837Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2141 849H2088V853H2141V849Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2194 849H2141V853H2194V849Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2247 849H2194V853H2247V849Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2300 849H2247V853H2300V849Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2141 853H2088V857H2141V853Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2194 853H2141V857H2194V853Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2247 853H2194V857H2247V853Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2300 853H2247V857H2300V853Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2148 865H2095V869H2148V865Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2201 865H2148V869H2201V865Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2254 865H2201V869H2254V865Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2307 865H2254V869H2307V865Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2148 869H2095V873H2148V869Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2201 869H2148V873H2201V869Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2254 869H2201V873H2254V869Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2307 869H2254V873H2307V869Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2156 881H2103V885H2156V881Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2209 881H2156V885H2209V881Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2262 881H2209V885H2262V881Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2315 881H2262V885H2315V881Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2156 885H2103V889H2156V885Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2209 885H2156V889H2209V885Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2262 885H2209V889H2262V885Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2315 885H2262V889H2315V885Z"
      fill="#7CAD14"
    />
    <path
      id="abaysan-2"
      d="M2247 1246H2194V1250H2247V1246Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2300 1246H2247V1250H2300V1246Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2353 1246H2300V1250H2353V1246Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2406 1246H2353V1250H2406V1246Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2459 1246H2406V1250H2459V1246Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2247 1250H2194V1254H2247V1250Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2300 1250H2247V1254H2300V1250Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2353 1250H2300V1254H2353V1250Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2406 1250H2353V1254H2406V1250Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2459 1250H2406V1254H2459V1250Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2259 1260H2206V1264H2259V1260Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2312 1260H2259V1264H2312V1260Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2365 1260H2312V1264H2365V1260Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2418 1260H2365V1264H2418V1260Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2259 1264H2206V1268H2259V1264Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2312 1264H2259V1268H2312V1264Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2365 1264H2312V1268H2365V1264Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2418 1264H2365V1268H2418V1264Z"
      fill="#585651"
    />
    <path
      id="seçilsan-2"
      d="M2073 801H2020V805H2073V801Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2126 801H2073V805H2126V801Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2179 801H2126V805H2179V801Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2232 801H2179V805H2232V801Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2285 801H2232V805H2285V801Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2073 805H2020V809H2073V805Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2126 805H2073V809H2126V805Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2179 805H2126V809H2179V805Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2232 805H2179V809H2232V805Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2285 805H2232V809H2285V805Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2077 817H2024V821H2077V817Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2130 817H2077V821H2130V817Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2183 817H2130V821H2183V817Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2236 817H2183V821H2236V817Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2289 817H2236V821H2289V817Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2077 821H2024V825H2077V821Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2130 821H2077V825H2130V821Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2183 821H2130V825H2183V821Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2236 821H2183V825H2236V821Z"
      fill="#7CAD14"
    />
    <path
      id="seçilsan-2"
      d="M2289 821H2236V825H2289V821Z"
      fill="#7CAD14"
    />
    <path
      id="abaysan-2"
      d="M2285 1120H2232V1124H2285V1120Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2338 1120H2285V1124H2338V1120Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2391 1120H2338V1124H2391V1120Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2444 1120H2391V1124H2444V1120Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2497 1120H2444V1124H2497V1120Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2550 1120H2497V1124H2550V1120Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2603 1120H2550V1124H2603V1120Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2656 1120H2603V1124H2656V1120Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2285 1124H2232V1128H2285V1124Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2338 1124H2285V1128H2338V1124Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2391 1124H2338V1128H2391V1124Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2444 1124H2391V1128H2444V1124Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2497 1124H2444V1128H2497V1124Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2550 1124H2497V1128H2550V1124Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2603 1124H2550V1128H2603V1124Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2656 1124H2603V1128H2656V1124Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2285 1134H2232V1138H2285V1134Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2338 1134H2285V1138H2338V1134Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2391 1134H2338V1138H2391V1134Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2444 1134H2391V1138H2444V1134Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2497 1134H2444V1138H2497V1134Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2550 1134H2497V1138H2550V1134Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2603 1134H2550V1138H2603V1134Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2656 1134H2603V1138H2656V1134Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2285 1138H2232V1142H2285V1138Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2338 1138H2285V1142H2338V1138Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2391 1138H2338V1142H2391V1138Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2444 1138H2391V1142H2444V1138Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2497 1138H2444V1142H2497V1138Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2550 1138H2497V1142H2550V1138Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2603 1138H2550V1142H2603V1138Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2656 1138H2603V1142H2656V1138Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2276 1148H2223V1152H2276V1148Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2329 1148H2276V1152H2329V1148Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2382 1148H2329V1152H2382V1148Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2435 1148H2382V1152H2435V1148Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2488 1148H2435V1152H2488V1148Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2541 1148H2488V1152H2541V1148Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2594 1148H2541V1152H2594V1148Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2647 1148H2594V1152H2647V1148Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2276 1152H2223V1156H2276V1152Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2329 1152H2276V1156H2329V1152Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2382 1152H2329V1156H2382V1152Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2435 1152H2382V1156H2435V1152Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2488 1152H2435V1156H2488V1152Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2541 1152H2488V1156H2541V1152Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2594 1152H2541V1156H2594V1152Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2647 1152H2594V1156H2647V1152Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2218 1176H2165V1180H2218V1176Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2271 1176H2218V1180H2271V1176Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2324 1176H2271V1180H2324V1176Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2377 1176H2324V1180H2377V1176Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2430 1176H2377V1180H2430V1176Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2483 1176H2430V1180H2483V1176Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2536 1176H2483V1180H2536V1176Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2589 1176H2536V1180H2589V1176Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2218 1180H2165V1184H2218V1180Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2271 1180H2218V1184H2271V1180Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2324 1180H2271V1184H2324V1180Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2377 1180H2324V1184H2377V1180Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2430 1180H2377V1184H2430V1180Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2483 1180H2430V1184H2483V1180Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2536 1180H2483V1184H2536V1180Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2589 1180H2536V1184H2589V1180Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2220 1190H2167V1194H2220V1190Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2273 1190H2220V1194H2273V1190Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2326 1190H2273V1194H2326V1190Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2379 1190H2326V1194H2379V1190Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2432 1190H2379V1194H2432V1190Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2485 1190H2432V1194H2485V1190Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2538 1190H2485V1194H2538V1190Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2220 1194H2167V1198H2220V1194Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2273 1194H2220V1198H2273V1194Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2326 1194H2273V1198H2326V1194Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2379 1194H2326V1198H2379V1194Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2432 1194H2379V1198H2432V1194Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2485 1194H2432V1198H2485V1194Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2538 1194H2485V1198H2538V1194Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2223 1204H2170V1208H2223V1204Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2276 1204H2223V1208H2276V1204Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2329 1204H2276V1208H2329V1204Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2382 1204H2329V1208H2382V1204Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2435 1204H2382V1208H2435V1204Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2488 1204H2435V1208H2488V1204Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2541 1204H2488V1208H2541V1204Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2223 1208H2170V1212H2223V1208Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2276 1208H2223V1212H2276V1208Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2329 1208H2276V1212H2329V1208Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2382 1208H2329V1212H2382V1208Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2435 1208H2382V1212H2435V1208Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2488 1208H2435V1212H2488V1208Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2541 1208H2488V1212H2541V1208Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2191 1166H2138V1170H2191V1166Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2244 1166H2191V1170H2244V1166Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2297 1166H2244V1170H2297V1166Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2350 1166H2297V1170H2350V1166Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2403 1166H2350V1170H2403V1166Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2456 1166H2403V1170H2456V1166Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2509 1166H2456V1170H2509V1166Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2562 1166H2509V1170H2562V1166Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2615 1166H2562V1170H2615V1166Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2191 1162H2138V1166H2191V1162Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2244 1162H2191V1166H2244V1162Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2297 1162H2244V1166H2297V1162Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2350 1162H2297V1166H2350V1162Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2403 1162H2350V1166H2403V1162Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2456 1162H2403V1166H2456V1162Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2509 1162H2456V1166H2509V1162Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2562 1162H2509V1166H2562V1162Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2615 1162H2562V1166H2615V1162Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2328 1106H2275V1110H2328V1106Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2381 1106H2328V1110H2381V1106Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2434 1106H2381V1110H2434V1106Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2487 1106H2434V1110H2487V1106Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2540 1106H2487V1110H2540V1106Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2593 1106H2540V1110H2593V1106Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2646 1106H2593V1110H2646V1106Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2328 1110H2275V1114H2328V1110Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2381 1110H2328V1114H2381V1110Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2434 1110H2381V1114H2434V1110Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2487 1110H2434V1114H2487V1110Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2540 1110H2487V1114H2540V1110Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2593 1110H2540V1114H2593V1110Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2646 1110H2593V1114H2646V1110Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2239 1218H2186V1222H2239V1218Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2292 1218H2239V1222H2292V1218Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2345 1218H2292V1222H2345V1218Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2398 1218H2345V1222H2398V1218Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2451 1218H2398V1222H2451V1218Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2504 1218H2451V1222H2504V1218Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2239 1222H2186V1226H2239V1222Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2292 1222H2239V1226H2292V1222Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2345 1222H2292V1226H2345V1222Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2398 1222H2345V1226H2398V1222Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2451 1222H2398V1226H2451V1222Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2504 1222H2451V1226H2504V1222Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2355 1092H2302V1096H2355V1092Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2408 1092H2355V1096H2408V1092Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2461 1092H2408V1096H2461V1092Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2514 1092H2461V1096H2514V1092Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2567 1092H2514V1096H2567V1092Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2620 1092H2567V1096H2620V1092Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2355 1096H2302V1100H2355V1096Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2408 1096H2355V1100H2408V1096Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2461 1096H2408V1100H2461V1096Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2514 1096H2461V1100H2514V1096Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2567 1096H2514V1100H2567V1096Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2620 1096H2567V1100H2620V1096Z"
      fill="#585651"
    />
    <path
      id="seçilsan-2"
      d="M2465 1056H2412V1060H2465V1056Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2518 1056H2465V1060H2518V1056Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2571 1056H2518V1060H2571V1056Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2624 1056H2571V1060H2624V1056Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2465 1060H2412V1064H2465V1060Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2518 1060H2465V1064H2518V1060Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2571 1060H2518V1064H2571V1060Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2624 1060H2571V1064H2624V1060Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2465 1040H2412V1044H2465V1040Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2518 1040H2465V1044H2518V1040Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2571 1040H2518V1044H2571V1040Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2624 1040H2571V1044H2624V1040Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2465 1044H2412V1048H2465V1044Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2518 1044H2465V1048H2518V1044Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2571 1044H2518V1048H2571V1044Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2624 1044H2571V1048H2624V1044Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2456 1024H2403V1028H2456V1024Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2509 1024H2456V1028H2509V1024Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2562 1024H2509V1028H2562V1024Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2615 1024H2562V1028H2615V1024Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2456 1028H2403V1032H2456V1028Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2509 1028H2456V1032H2509V1028Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2562 1028H2509V1032H2562V1028Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2615 1028H2562V1032H2615V1028Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2452 1008H2399V1012H2452V1008Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2505 1008H2452V1012H2505V1008Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2558 1008H2505V1012H2558V1008Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2611 1008H2558V1012H2611V1008Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2452 1012H2399V1016H2452V1012Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2505 1012H2452V1016H2505V1012Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2558 1012H2505V1016H2558V1012Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2611 1012H2558V1016H2611V1012Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2442 992H2389V996H2442V992Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2495 992H2442V996H2495V992Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2548 992H2495V996H2548V992Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2601 992H2548V996H2601V992Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2442 996H2389V1000H2442V996Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2495 996H2442V1000H2495V996Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2548 996H2495V1000H2548V996Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2601 996H2548V1000H2601V996Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2438 976H2385V980H2438V976Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2491 976H2438V980H2491V976Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2544 976H2491V980H2544V976Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2597 976H2544V980H2597V976Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2438 980H2385V984H2438V980Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2491 980H2438V984H2491V980Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2544 980H2491V984H2544V980Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2597 980H2544V984H2597V980Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2432 960H2379V964H2432V960Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2485 960H2432V964H2485V960Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2538 960H2485V964H2538V960Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2591 960H2538V964H2591V960Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2432 964H2379V968H2432V964Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2485 964H2432V968H2485V964Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2538 964H2485V968H2538V964Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2591 964H2538V968H2591V964Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2424 944H2371V948H2424V944Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2477 944H2424V948H2477V944Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2530 944H2477V948H2530V944Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2583 944H2530V948H2583V944Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2424 948H2371V952H2424V948Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2477 948H2424V952H2477V948Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2530 948H2477V952H2530V948Z"
      fill="#E62D43"
    />
    <path
      id="seçilsan-2"
      d="M2583 948H2530V952H2583V948Z"
      fill="#E62D43"
    />
    <path
      d="M2191 828H2177V834H2191V828Z"
      fill="#C4C4C4"
    />
    <path
      d="M2209 828H2195V834H2209V828Z"
      fill="#C4C4C4"
    />
    <path
      d="M2190.5 831L2307 830.5"
      stroke="#F06730"
    />
    <path
      d="M2309 828H2305V832H2309V828Z"
      fill="#0A0000"
    />
    <path
      d="M2329 908H2325V912H2329V908Z"
      fill="#0A0000"
    />
    <path
      d="M2378 917H2374V921H2378V917Z"
      fill="#0A0000"
    />
    <path
      d="M2413 908H2409V912H2413V908Z"
      fill="#0A0000"
    />
    <path
      d="M2325.5 910H2239.5"
      stroke="#F06730"
    />
    <path
      d="M2241 908H2228V913H2241V908Z"
      fill="#C4C4C4"
    />
    <path
      d="M2259 908H2246V913H2259V908Z"
      fill="#C4C4C4"
    />
    <path
      d="M2369.5 921.5H2363.5V938.5H2369.5V921.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M2379.5 921.5H2373.5V938.5H2379.5V921.5Z"
      fill="#C4C4C4"
      stroke="black"
    />
    <path
      d="M2398 1003H2393V1008H2398V1003Z"
      fill="#0A0000"
    />
    <path
      d="M2398 1007H2424"
      stroke="#F46024"
    />
    <path
      d="M2390 987H2385V991H2390V987Z"
      fill="#0A0000"
    />
    <path
      d="M2329 1006.5H2283"
      stroke="#F06730"
    />
    <path
      d="M2332 1003H2327V1009H2332V1003Z"
      fill="#0A0000"
    />
    <path
      d="M2284 1004H2273V1009H2284V1004Z"
      fill="#C4C4C4"
    />
    <path
      d="M2434 1004H2423V1008H2434V1004Z"
      fill="#C4C4C4"
    />
    <path
      d="M2553 987H2534V992H2553V987Z"
      fill="#C4C4C4"
    />
    <path
      d="M2525 987H2506V992H2525V987Z"
      fill="#C4C4C4"
    />
    <path
      d="M2486 908H2477V912H2486V908Z"
      fill="#C4C4C4"
    />
    <path
      d="M2501 908H2492V912H2501V908Z"
      fill="#C4C4C4"
    />
    <path
      id="abaysan-2"
      d="M2247 1232H2194V1236H2247V1232Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2300 1232H2247V1236H2300V1232Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2353 1232H2300V1236H2353V1232Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2406 1232H2353V1236H2406V1232Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2459 1232H2406V1236H2459V1232Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2247 1236H2194V1240H2247V1236Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2300 1236H2247V1240H2300V1236Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2353 1236H2300V1240H2353V1236Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2406 1236H2353V1240H2406V1236Z"
      fill="#585651"
    />
    <path
      id="abaysan-2"
      d="M2459 1236H2406V1240H2459V1236Z"
      fill="#585651"
    />
    <path
      d="M2396 1243H2393V1246H2396V1243Z"
      fill="#0A0000"
    />
    <path
      d="M2326 1242H2317V1246H2326V1242Z"
      fill="#C4C4C4"
    />
    <path
      d="M2339 1242H2330V1246H2339V1242Z"
      fill="#C4C4C4"
    />
    <path
      d="M2395 1174L2468.5 1173.5"
      stroke="#F06730"
    />
    <path
      d="M2359 1102H2347V1106H2359V1102Z"
      fill="#C4C4C4"
    />
    <path
      d="M2375 1102H2363V1106H2375V1102Z"
      fill="#C4C4C4"
    />
    <path
      d="M2395 1174H2282"
      stroke="#F06730"
    />
    <path
      d="M2282 1171H2270V1176H2282V1171Z"
      fill="#C4C4C4"
    />
    <path
      d="M2299 1171H2287V1176H2299V1171Z"
      fill="#C4C4C4"
    />
    <path
      d="M2396 1173H2393V1176H2396V1173Z"
      fill="#0A0000"
    />
  </svg>
</template>
<script>
export default {

};
</script>
