<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Privacy Policy
          </v-card-title>

          <v-card-text>
            <div id="dialogsantralmap">
              <SantralMap />
            </div>
          </v-card-text>

          <v-divider />
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SantralMap from '@/views/images/Svg/Nevres1.vue';
import helper from '@/api/helper';
import * as SvgMap from '@/api/SantralList/SvgMap';

console.log(helper);
const getTransformParameters = (element) => {
  console.log(element);
  const { transform } = element.style;
  let scale = 1;
  let x = 0;
  let y = 0;

  if (transform.includes('scale')) {
    scale = parseFloat(transform.slice(transform.indexOf('scale') + 6));
  }
  if (transform.includes('translateX')) {
    x = parseInt(transform.slice(transform.indexOf('translateX') + 11));
  }
  if (transform.includes('translateY')) {
    y = parseInt(transform.slice(transform.indexOf('translateY') + 11));
  }

  return { scale, x, y };
};

const getTransformString = (scale, x, y) => `scale(${scale}) ` + `translateX(${x}%) translateY(${y}%)`;

const zoom = (direction) => {
  const { scale, x, y } = getTransformParameters(svg);
  let dScale = 0.1;
  if (direction === 'out') dScale *= -1;
  if (scale === 0.1 && direction == 'out') dScale = 0;
  svg.style.transform = getTransformString(scale + dScale, x, y);
};

function hover() {
  console.log('hover');
  const element = document.querySelector('#dialogsantralmap #nevres_bilecik_1');

  console.log(element);
  element.addEventListener('wheel', (event) => {
    console.log(event);
  });
}

export default {
  components: {
    SantralMap,
  },
  props: ['santraldata'],
  data: () => ({
    svgg: '',
    svg_object: null,
    santralMapDetail: false,
  }),
  computed: {},
  watch: {

      santraldata(){
          console.log(this.santraldata)
      }
  },
  created() {},
  mounted() {
    console.log('svg santral');
  /*   const prefix = helper.getParameterByName('prefix');
    this.getLiveError(prefix); */
  },
  methods: {
    opensvg() {
      this.santralMapDetail = true;
      hover();
    },
    getLiveError(prefix) {
      const params = {
        limit: 5,
        skip: 0,
        startDate: '2021-12-01',
        prefix,
      };
      const tempdata = SvgMap.default.getLiveError(params);
      tempdata.then((res) => {
        for (let i = 1; i < 20; i++) {
          for (let j = 1; j <= 20; j++) {
            console.log(`kahrasan-1-com${i}-dc${j}`);
            const svgg = document.getElementById(`kahrasan-1-com${i}-dc${j}`);
            console.log(svgg);

            svgg.setAttribute('fill', '#ff001d');
          }
        }
      });
    },
  },
};
</script>

<style scoped>
#santralmap svg {
  padding: 20 20 20 20;
  margin: 20 20 20 20;
  height: 650px;
  width: 1200px;
}

#dialogsantralmap svg {
  padding: 20 20 20 20;
  margin: 20 20 20 20;
  height: 100%;
  width: 100%;
}
</style>
