<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <div id="map" />
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6" md="2" lg="2" class="d-flex">
          <v-col v-for="val in totalVal" :key="val.key" cols="12">
            <v-card
              elevation="2"
              outlined
              class="mx-auto pl-2 text-center"
              max-width="250"
            >
              <p class="text-h6 text--primary">
                {{ val.text }}
              </p>

              <p class="text-h7 text--primary">
                {{ val.value }} {{ val.unit }}
              </p>
            </v-card>
          </v-col>
        </v-col>
      </v-row>

      <v-dialog v-model="santralMapDetail">
        <SvgSantralComponent />
      </v-dialog>
    </v-container>
  </div>
</template>
 


<script type="text/javascript">
import NotifyModal from "@/views/components/Notification/NotifyModal.vue";
import moment from "moment";
import i18n from "@/locale";
import gmapsInit from "@/utils/gmaps";
import { getSantralStatsData } from "@/api/Request/santral";
import SvgSantralComponent from "@/views/components/SantralList/SvgSantralComponent.vue";
let vuecomponent = null;
let infowindow;
window.myFunc = function () {
  vuecomponent.santralMapDetail = true;
};

export default {
  name: "App",
  components: {
    NotifyModal,
    SvgSantralComponent,
  },
  data() {
    return {
      googleData: [],
      locations: [],
      markers: [],
      markerCluster: null,
      santralMapDetail: false,
      startDate: moment().format("YYYY-MM-DD"),
      finishDate: moment().format("YYYY-MM-DD"),
      google: null,
      map: null,
      myMarkers: [],
      isLoadingMyMarkers: false,
      isClickFilterButton: false,
      totalVal: {},
      items: 5,

      santral_items: [],
      itemsPerPage: 500,
      tableModel: [],
      santral_headers: [],
      alarmDialog: false,
      selectedItemprefix: null,
      prefix: null,
    };
  },
  computed: {},
  watch: {},
  async created() {
    vuecomponent = this;
    this.google = await gmapsInit();
    const options = {
      zoom: 2,
      center: { lat: 35, lng: 40 },
      gestureHandling: "cooperative",
    };

    const { google } = this;
    this.map = new google.maps.Map(document.getElementById("map"), options);

    this.initializeGoogleMaps();
  },
  mounted() {},
  methods: {
    initializeGoogleMaps() {
      this.isLoadingMyMarkers = true;
      this.callGoogleMaps();
    },
    selectedItem(item) {
      console.log(item);
      this.selectedItemprefix = item.prefix;
      this.alarmDialog = true;
    },

    callGoogleMaps() {
      try {
        const { google, map } = this;
        map.setCenter({
          lat: 39.191746,
          lng: 35.5661104,
        });
        map.setZoom(6);

        const { mainPrefix, prefixList } = this.$store.getters;

        const date = this.startDate;
        const formdata = {
          condiniton: {
            date,
            mainPrefix,
            prefixList,
          },
        };

        getSantralStatsData(formdata).then((res) => {
          
          if (res.status !== 200) {
            return;
          }
          if (res.data.success === "false") {
            return;
          }
          Object.keys(res.data.data).forEach((i) => {
            this.addMarker(res.data.data[i], google, map);
          });
        });
      } catch (error) {
        console.error(error);
      }
    },

    locationControl(props) {
      if (props.hasOwnProperty("location")) {
        if (props.location === null || props.location === undefined) {
          return "false";
        }
        if (
          props.location.hasOwnProperty("lat") &&
          props.location.hasOwnProperty("lon")
        ) {
          if (props.location.lat === null || props.location.lon === undefined) {
            return "false";
          }

          return "true";
        }
      }
      return "false";
    },

    addMarker(props, google, map) {
      let control = this.locationControl(props);
      if (control === "false") {
        return;
      }

      let icon = require("@/views/images/icon/solar_panel_icon_success.svg");

      if (props.Error > 0 || props.Warning > 0) {
        icon = require("@/views/images/icon/solar_panel_icon_error.svg");
      }

      const coords = {};

      coords.lat = props.location.lat;
      coords.lng = props.location.lon;

      const marker = new google.maps.Marker({
        position: coords,
        label: {
          color: "white",
          fontSize: "10px",
        },
        animation: google.maps.Animation.DROP,
        icon: {
          url: icon,
          scaledSize: new google.maps.Size(30, 30),
          fillColor: "#0000ff",
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(50, 50),
        },
        flat: true,
        optimized: false,
        visible: true,
        map,
      });
       

      const obj = {};
      obj.DailyEnergy = props.DailyEnergy ? props.DailyEnergy : 0;
      obj.IstantaneousPower = props.IstantaneousPower
        ? props.IstantaneousPower
        : 0;
      obj.TheoreticalPower = props.TheoreticalPower
        ? props.TheoreticalPower
        : 0;

      obj.Error = props.Error ? props.Error : 0;
      obj.Warning = props.Warning ? props.Warning : 0;

      const contentString =
        `<span style='font-size: 16px;font-weight:bold'>
         ${props.company_name}</span> <br> ` +
        `<b>Günlük Enerji </b>: ${obj.DailyEnergy.toFixed(2)} kWh <br> ` +
        `<b>Anlık Güç</b>: ${obj.IstantaneousPower.toFixed(2)} kW <br> ` +
        `<b>Teorik Güç</b>: ${obj.TheoreticalPower.toFixed(2)} kW <br> `;
       
      infowindow = new google.maps.InfoWindow({
        content: "",
      });
      google.maps.event.addListener(marker, "click", function () {
        infowindow.setContent(contentString);
        infowindow.open(map, this);
      });
    },
  },
};
</script>

<style scoped>
#map {
  height: 600px;
  width: 100%;
}

#contents {
  height: 300px;
  width: 200px;
}
#svgsantralcomp {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
</style>
